import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { uuid, copyObject, includeKeys } from '../../../../helpers'
import {
  useClipboard, useDebounce, useDialog, useTemplateRoot, getTemplateState,
} from '../../../../hooks'
import { TemplateProvider } from '../../useTemplate'
import { renderSection, newSection } from '../Section'
import Elements, { newElement, getElementDefault, onUpdateElementConfig } from '../Element'
import { getTemplateElement, getTemplateSection } from '../../../../helpers/template'
import { useKeyUp, useKeyDown } from '../../../../hooks/useKeyPress'

const TemplateConfigContext = React.createContext({})
const useTemplateConfig = () => React.useContext(TemplateConfigContext)

const scrollToEl = (id) => {
  if (document) {
    setTimeout(() => {
      const el = document.querySelector(`#${id}`)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }, 50)
  }
}

const templateConfigHooks = ({
  config: configProp,
  onChange: onChangeProp,
  uploadImage,
  saveToSharedSection,
  SharedSectionPanel,
  sharedSections,
  name,
  slug,
  root,
  ...rest
}) => {
  const [config, setConfig] = React.useState(configProp)
  const [updated, setUpdated] = React.useState(0)
  const { copy, clear, inspect } = useClipboard()
  const sendDialog = useDialog()
  const navigate = useNavigate()
  const [dragging, setDragging] = React.useState()
  const [showDrawerMenu, setShowDrawerMenu] = React.useState(false)

  const sectionIndex = (section) => (
    config.sections.findIndex(({ variables }) => (
      variables.id === section.variables.id
    ))
  )

  const onChangeNow = (props) => {
    if (onChangeProp) return onChangeProp(props)
    return null
  }

  const onDragStart = ({ active }) => setDragging(active.id)
  const onDragEnd = () => setDragging(undefined)

  // Trigger onChange if there has been a change
  const onChange = useDebounce((props) => {
    if (onChangeProp) return onChangeProp(props)
    return null
  }, [slug], 500)

  const updateTemplate = ({ config: update, cascade }) => {
    let conf
    if (update) {
      conf = {
        ...update,
        sections: update.sections.map((section) => (
          renderSection({ section, templateVariables: update.variables, updateVars: cascade })
        )),
      }
      setConfig(conf)
      setUpdated({})
    }
    onChange({ config: conf })
  }

  const saveTemplate = () => {
    onChange({ config })
  }

  // Uses Array.splice to update, remove, or add a section to the sections array
  const updateConfigSection = ({
    section, index, replace, now, skipUpdate,
  }) => {
    const spliceArgs = [index, replace ? 1 : 0]
    if (section) spliceArgs.push(section)

    config.sections.splice(...spliceArgs)
    setUpdated({})

    if (now) {
      onChangeNow({ config })
    } else if (!skipUpdate) {
      onChange({ config })
    }
  }

  const updateSection = ({ config: section, now, updateVars }) => {
    const updatedSection = renderSection({
      section, templateVariables: config.variables, updateVars,
    })

    updateConfigSection({
      section: updatedSection,
      index: sectionIndex(updatedSection),
      replace: true,
      now,
    })
  }

  const copySection = (data) => {
    copy('section', newSection({ section: data, templateVariables: config.variables }))
  }

  const addSection = ({ section, sectionId = section?.variables?.id }) => {
    const sectionCopy = newSection({ section, templateVariables: config.variables })
    const tempSection = sectionId ? getTemplateSection({ config, sectionId }) : null
    const index = tempSection?.section
      ? tempSection.sectionIndex + 1
      : config.sections.length

    updateConfigSection({
      section: sectionCopy,
      index,
    })

    navigate(`section/${sectionCopy.variables.id}`, { replace: true })
    scrollToEl(sectionCopy.variables.id)
    return sectionCopy
  }

  // Get an element and its place in a section
  // element, elementId: Accepts an element object or an elementId
  // Returns: a sectionElement ({ section, element, columnIndex, elementIndex })
  const getSectionElement = ({ element, elementId = element?.id }) => (
    getTemplateElement({ config, elementId })
  )

  // Add an element to a section in the column.
  // - name: will look up the element by name
  // - config: default settings for the new element
  const insertElement = ({
    section, columnIndex = 0, elementIndex, name: elementName, config: elementConfig, show,
  }) => {
    const element = newElement({
      element: Elements[elementName],
      config: elementConfig,
      templateVariables: config.variables,
    })

    // Place the element last if element index is not defined
    const index = typeof elementIndex === 'number'
      ? elementIndex
      // Default to the position of the last element in the column
      : section.variables.columns[columnIndex].elements.length

    section.variables.columns[columnIndex].elements.splice(index, 0, element)
    updateSection({ config: section, now: true })

    if (show) {
      navigate(`./element/${element.id}`, { replace: true })
      scrollToEl(element.id)
    }
    return element
  }

  const addElement = ({
    element,
    sectionId,
    elementId = element?.id,
    columnIndex: colIndex = 0,
  }) => {
    const { section } = getTemplateSection({ config, sectionId })
    const tempEl = getTemplateElement({ config, sectionId, elementId })

    const columnIndex = tempEl.element ? tempEl.columnIndex : colIndex
    const elementIndex = tempEl.element
      ? tempEl.elementIndex + 1
      : section.variables.columns[columnIndex].elements.length

    insertElement({
      section,
      columnIndex,
      elementIndex,
      name: element.type,
      config: element.config,
      show: true,
    })
  }

  const copyElement = (data) => {
    copy('element', copyObject(data))
  }

  const changeElementOrder = ({
    from, to,
  }) => {
    const updatedTemplate = copyObject(config)
    const moved = updatedTemplate.sections[from.sectionIndex]
      .variables.columns[from.columnIndex].elements.splice(from.elementIndex, 1)[0]
    updatedTemplate.sections[to.sectionIndex]
      .variables.columns[to.columnIndex].elements.splice(to.elementIndex, 0, moved)

    config.sections = updatedTemplate.sections
    setUpdated({})
  }

  // Removes an element by id.
  // element, elementId: Accepts an element object or an elementId
  const removeElement = ({ element, elementId = element?.id }) => {
    const id = element?.id || elementId
    const { section, columnIndex, elementIndex } = getTemplateElement({ config, elementId: id })
    section.variables.columns[columnIndex].elements.splice(elementIndex, 1)

    updateSection({ config: section, now: true })
  }

  // Updates an element by id.
  // element, elementId: Accepts an element object or an elementId
  // changes: object to merge over current configuration
  const updateElement = ({ changes, element: Element, elementId = Element?.id }) => {
    const {
      element, section, columnIndex, elementIndex,
    } = getTemplateElement({ config, elementId })

    // Only permit change if it is found in initial object default
    const validChanges = includeKeys(changes, getElementDefault(element))

    if (Object.keys(validChanges).length) {
      const newConfig = onUpdateElementConfig({
        element: {
          ...element,
          config: { ...element.config, ...validChanges },
        },
        columnConfig: section.variables.columns[columnIndex].config,
        sectionConfig: section.variables.config,
        varPath: `columns.${columnIndex}.elements.${elementIndex}.config`,
        templateVariables: config.variables,
      })
      section.variables
        .columns[columnIndex]
        .elements[elementIndex]
        .config = newConfig

      updateSection({ config: section })
    }
  }

  const confirmDeleteElement = ({ element, sectionId }) => {
    const onConfirm = () => {
      removeElement({ element })
      navigate(`./section/${sectionId}`, { replace: true })
    }
    const { config: { text, src, content } } = element

    // If they have configured the element, ask before deleting
    if (text || src || content) {
      sendDialog({
        onConfirm,
        title: `Remove "${element.type}"?`,
        confirmText: 'Remove',
        destructive: true,
      })
    } else {
      onConfirm()
    }
  }

  // Handles the drop event for an image, uploading it and updating the element { src }
  const dropImage = uploadImage ? async ({ data, event }) => {
    const imageElement = '.enveloop-element[data-type=Image]'
    const el = event.target.closest(imageElement)

    if (el) {
      const { url } = await uploadImage(data[0])
      updateElement({ elementId: el.id, changes: { src: url } })
    }
  } : null

  const deleteSection = ({ section, index = sectionIndex(section) }) => {
    updateConfigSection({ index, replace: true, now: true })
  }

  const confirmDeleteSection = ({ section }) => {
    const onConfirm = () => {
      deleteSection({ section })
      navigate('./', { replace: true })
    }
    sendDialog({
      onConfirm,
      title: `Remove "${section.variables.config.name}"?`,
      confirmText: 'Remove',
      destructive: true,
    })
  }

  const addSharedSection = ({ section, sectionId }) => {
    let index = config.sections.length
    if (sectionId) {
      const tempSection = getTemplateSection({ config, sectionId })
      index = tempSection.sectionIndex + 1
    }

    const id = `section-${uuid()}`
    const sectionCopy = copyObject({
      ...section.liveConfig,
      sharedSectionId: section.id,
      variables: { ...section.liveConfig.variables, id },
    })

    updateConfigSection({ index, section: sectionCopy })
    navigate(`section/${id}`, { replace: true })
    scrollToEl(sectionCopy.variables.id)

    return newSection
  }

  const changeSectionOrder = ({ from, to }) => {
    config.sections.splice(to, 0, config.sections.splice(from, 1)[0])
    setUpdated({})
    onChange({ config })
  }

  const convertToSharedSection = async (section) => {
    if (saveToSharedSection) {
      const response = await saveToSharedSection({ section, publish: true })
      if (response && response?.data?.sharedSection) {
        const { data: { sharedSection } } = response
        const updatedSection = copyObject(section)
        updatedSection.sharedSectionId = sharedSection.id
        updatedSection.variables.config.name = sharedSection.name

        updateSection({ config: updatedSection })
      }
    }
  }

  const unlinkSharedSection = (section) => {
    const sectionCopy = copyObject(section)
    delete sectionCopy.sharedSectionId

    updateConfigSection({
      section: {
        ...newSection({ section: sectionCopy, templateVariables: config.variables }),
      },
      index: sectionIndex(section),
      replace: true,
    })
  }

  React.useEffect(() => {
    setConfig(configProp)
    setUpdated({})
  }, [configProp])

  useKeyUp({
    key: 'Backspace',
    onPress: () => {
      const { section, sectionId, element } = getTemplateState({ root, config })
      if (element) {
        confirmDeleteElement({ element, sectionId })
      } else if (section) {
        confirmDeleteSection({ section })
      }
    },
  })
  useKeyDown({
    key: ['cmd c', 'ctrl c'],
    onPress: () => {
      const { section, element } = getTemplateState({ root, config })
      if (element) {
        copyElement(element)
      } else if (section) {
        copySection(section)
      }
    },
  })
  useKeyDown({
    key: ['cmd v', 'ctrl v'],
    onPress: () => {
      const { sectionId, elementId } = getTemplateState({ root, config })
      const { name: type, data } = inspect()
      if (type === 'element') {
        addElement({ element: data, elementId, sectionId })
        clear()
      } else if (type === 'section') {
        addSection({ section: data, sectionId })
        clear()
      }
    },
  })
  React.useEffect(() => () => onChange.cancel(), [slug])

  return React.useMemo(() => ({
    ...rest,
    root,
    name,
    slug,
    config,
    copySection,
    updateSection,
    deleteSection,
    confirmDeleteSection,
    addSection,
    changeSectionOrder,
    updateTemplate,
    convertToSharedSection,
    unlinkSharedSection,
    SharedSectionPanel,
    sharedSections,
    showDrawerMenu,
    setShowDrawerMenu,
    addSharedSection,
    getSectionElement,
    insertElement,
    copyElement,
    addElement,
    confirmDeleteElement,
    removeElement,
    updateElement,
    dropImage,
    uploadImage,
    saveTemplate,
    changeElementOrder,
    dragging,
    onDragStart,
    onDragEnd,
  }), [dragging, updated, name, slug, showDrawerMenu])
}

const TemplateConfigProvider = ({
  mode,
  type,
  children,
  ...rest
}) => {
  const root = useTemplateRoot()
  const value = templateConfigHooks({
    ...rest,
    mode,
    type,
    root,
  })

  const { sectionId, elementId } = getTemplateState({ ...rest, root })
  const state = React.useMemo(() => ({ path: { sectionId, elementId } }), [sectionId, elementId])

  return (
    <TemplateConfigContext.Provider value={value}>
      <TemplateProvider config={value.config} type={type} state={state} hooks={value} mode={mode}>
        {children}
      </TemplateProvider>
    </TemplateConfigContext.Provider>
  )
}

TemplateConfigProvider.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  saveToSharedSection: PropTypes.func,
  SharedSectionPanel: PropTypes.func,
  sharedSections: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  config: PropTypes.shape({
    sections: PropTypes.array,
    variables: PropTypes.object,
  }).isRequired,
  mode: PropTypes.string,
}

TemplateConfigProvider.defaultProps = {
  onChange: () => {},
  sharedSections: [],
  saveToSharedSection: undefined,
  SharedSectionPanel: () => null,
  mode: 'builder',
}

export {
  TemplateConfigProvider,
  useTemplateConfig,
  templateConfigHooks,
}
