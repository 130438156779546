import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid, Stack, Input, Block,
  RadioInputWrapper, Shelf, Text, HiddenInput,
  useFormRef, copyTemplate, renderSection,
} from '@level'

import basic from './basic'
import simple from './simple'
import stellar from './stellar'

import { ColorPicker } from './ColorPicker'
import { BlankTemplate } from './blank'
import { TemplatePreview } from '../preview'

const themes = { stellar, simple, basic }
const themeLabels = {
  stellar: 'Stellar',
  simple: <>Day&nbsp;&amp;&nbsp;Night</>,
  basic: 'No Frills',
  blank: 'Blank',
}

const getConfig = ({ template, color, ...rest }) => {
  const { config } = copyTemplate({ config: template.config({ color, ...rest }), type: 'email' })

  return {
    ...config,
    // Ensure that templates sections are fully rendered
    sections: config.sections.map((section) => (
      renderSection({
        section, templateVariables: config.variables, updateVars: true,
      })
    )),
  }
}

const Templates = ({ theme, canChooseName, ...props }) => {
  const { setValue, watch } = useFormRef()
  const { templates, colors } = themes[theme]

  const inputColor = watch({ name: 'color' })
  const templateName = watch({ name: 'template' })
  const color = colors.includes(inputColor) ? inputColor : colors[0]

  React.useEffect(() => {
    setValue('color', colors[0])
  }, [theme])

  React.useEffect(() => {
    if (color && templateName) {
      const template = templates.find((t) => t.name === templateName)

      setValue('name', template.name)
      setValue('config', getConfig({
        template, color, ...props,
      }))
    }
  }, [theme, color, templateName])

  return (
    <>
      <Block className="theme-gallery-header" space={[6, 5, 6, 8]}>
        <Shelf align="split" valign="center">
          <Text tag="h4">Select a Template</Text>
          { colors.length > 1 ? <ColorPicker colors={colors} name="color" /> : null }
        </Shelf>
      </Block>
      <Block space={8} className="theme-gallery-content">
        <div className="theme-gallery-themes">
          <Grid gap={8} columnMin={180} fill>
            { templates.map((template) => (
              <TemplatePreview
                key={template.name}
                type="email"
                name={template.name}
                config={getConfig({
                  template, color, ...props,
                })}
              />
            )) }
          </Grid>
        </div>
      </Block>
      { canChooseName ? (
        <Block className="theme-gallery-footer" space={[6, 5, 6, 8]}>
          <Input name="name" label="Name your template" required />
        </Block>
      ) : null }
    </>
  )
}

Templates.propTypes = {
  theme: PropTypes.string.isRequired,
  canChooseName: PropTypes.bool,
}

Templates.defaultProps = {
  canChooseName: true,
}

const ThemeCard = ({
  value, label = value, icon, ...rest
}) => (
  <RadioInputWrapper name="theme" value={value} label={themeLabels[label]} theme="card" {...rest}>
    <div className="theme-gallery-card">
      <Text icon={{ name: icon, size: 5, space: 4 }}>{themeLabels[label]}</Text>
    </div>
  </RadioInputWrapper>
)

ThemeCard.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
}

ThemeCard.defaultProps = {
  label: undefined,
  icon: undefined,
}

const EmailTemplateGallery = (props) => {
  const { watch, defaultValues } = useFormRef()
  const theme = watch({ name: 'theme' }) || defaultValues.theme

  return (
    <>
      <HiddenInput name="type" value="email" />
      <Grid templateColumns="min-content 1fr" className="theme-gallery">
        <Stack className="theme-gallery-drawer" gap={6}>
          {Object.keys(themes).map((t) => (
            <ThemeCard key={t} value={t} icon={`theme-icon-${t}`} defaultChecked={theme === t} />
          ))}
          <ThemeCard value="blank" icon="theme-icon-blank" defaultChecked={theme === 'blank'} />
        </Stack>
        <div className="theme-gallery-main">
          { theme && theme !== 'blank' ? <Templates theme={theme} {...props} /> : <BlankTemplate {...props} /> }
        </div>
      </Grid>
    </>
  )
}

export {
  EmailTemplateGallery,
  themes,
}
