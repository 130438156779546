import React from 'react'
import PropTypes from 'prop-types'
import {
  Navigate, Route, Routes, useLocation,
} from 'react-router-dom'
import { PreviewEmailTemplate } from '@app/views/templates/email/previewEmailTemplate'

import {
  Main, ScrollPanel, Spacer, Breadcrumbs, Header, Text, Loading,
  SectionConfigForm, ElementConfig, Drawer, Button,
  FileDrop, DrawerMenu,
  useTemplateConfig,
} from '@level'

import { useSharedSection } from './provider'
import { SharedSectionMenuActions } from './actions'

const TemplateReturn = ({ templateUrl }) => (
  <Button
    to={templateUrl}
    icon={{
      name: 'reply',
      gap: 4,
    }}
    theme="menu"
    text="Back to Template"
  />
)

TemplateReturn.propTypes = {
  templateUrl: PropTypes.string.isRequired,
}

const SharedSectionConfig = () => {
  const {
    sharedSection,
    updateSection,
  } = useSharedSection()

  const update = ({ config }) => {
    updateSection({ name: config.variables.config.name, config })
  }

  return (
    <ScrollPanel>
      <SectionConfigForm
        id={sharedSection.updatedAt}
        updateSection={update}
        section={sharedSection.previewConfig}
      />
    </ScrollPanel>
  )
}

const SharedSection = () => {
  const { sharedSection } = useSharedSection()
  const { dropImage, showDrawerMenu, ...props } = useTemplateConfig()
  const location = useLocation()
  const [templateUrl, setTemplateUrl] = React.useState()

  React.useEffect(() => {
    setTemplateUrl(location?.state?.templateUrl)
  }, [sharedSection.id])

  return (
    <Main>
      <Header primary align="split">
        <Breadcrumbs size={3}>
          <Text>{sharedSection?.name || <Loading />}</Text>
        </Breadcrumbs>
        <Spacer grow />
        { templateUrl ? <TemplateReturn templateUrl={templateUrl} /> : null }
        <SharedSectionMenuActions />
      </Header>
      <Main drawer={(
        <Drawer>
          <ScrollPanel>
            { showDrawerMenu ? (
              <DrawerMenu />
            ) : (
              <Routes>
                <Route path="section/:sectionId/*" element={<SharedSectionConfig />} />
                <Route path="element/:elementId" element={<ElementConfig />} />
                {/* Enforce mounting at ./section/sectionId/ */}
                <Route path="*" element={<Navigate to={`section/${sharedSection.previewConfig.variables.id}/`} replace />} />
              </Routes>
            ) }
          </ScrollPanel>
        </Drawer>
      )}
      >
        <FileDrop type="image" onDrop={dropImage}>
          <PreviewEmailTemplate {...props} type="email" mode="section-builder" />
        </FileDrop>
      </Main>
    </Main>
  )
}

export {
  SharedSection,
}
