import { dasherize } from './text'

// Return an object where only the included keys are present
const includeKeys = (obj, includedKeys) => {
  const include = Array.isArray(includedKeys) ? includedKeys : Object.keys(includedKeys)
  return Object.keys(obj).reduce((all, key) => (
    (include.includes(key)) ? { ...all, [key]: obj[key] } : all
  ), {})
}

const excludeKeys = (obj, excludedKeys) => (
  Object.keys(obj).reduce((all, key) => (
    (excludedKeys.includes(key)) ? all : { ...all, [key]: obj[key] }
  ), {})
)

const isEmpty = (val, ...ignore) => (
  typeof val === 'undefined' || val === null || val === '' || ignore.includes(val)
)

// Remove all empty values from an object.
// If `forKeys` is set, only filter empty values on those keys
const filterEmptyValues = (obj, forKeys) => (
  Object.keys(obj).reduce((all, key) => (
    // Value is truthy or there are key selections and this isn't one of them
    (!isEmpty(obj[key]) || (forKeys && !forKeys.includes(key))) ? { ...all, [key]: obj[key] } : all
  ), {})
)

const copyObject = (obj) => JSON.parse(JSON.stringify(obj))

const toSearchString = (obj) => {
  const params = new URLSearchParams()
  Object.keys(obj).forEach((key) => {
    params.append(key, obj[key])
  })
  return params.toString()
}

const searchFilterObj = ({ name, operator, value }, operators = {}) => {
  const filter = { [name]: value }
  if (operator && operator !== operators[name]) filter[`${name}Op`] = operator
  return filter
}

const searchToObject = (str) => {
  const params = new URLSearchParams(str)
  const obj = {}

  params.forEach((val, key) => {
    if (obj[key]) {
      obj[key] = Array([obj[key], val]).flat(2)
    } else obj[key] = val
  })

  return obj
}

const toOperatorParams = (defaultOperators, values) => (
  values.filter((arg) => arg).reduce((acc, { name, operator, value }) => {
    const param = { [name]: value }
    if (operator && operator !== defaultOperators[name]) param[`${name}Op`] = operator
    if (!operator) param[`${name}Op`] = null
    return { ...acc, ...param }
  }, {})
)

function isEqual(a, b) {
  const ta = typeof a
  const tb = typeof b
  return a && b && ta === 'object' && ta === tb ? (
    Object.keys(a).length === Object.keys(b).length
      && Object.keys(a).every((key) => isEqual(a[key], b[key]))
  ) : (a === b)
}

const expandAria = (aria) => {
  const obj = {}

  if (aria) {
    Object.keys(aria).forEach((key) => {
      obj[`aria-${dasherize(key)}`] = aria[key]
    })
  }
  return obj
}

const isImageFile = (file) => !file || !!file.type.match(/image/)

const imageData = (file) => (
  file ? new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  }) : null
)

// Extract the image data to render a src preview
const imageFileSrc = async (fileList) => {
  if (!fileList) return null
  if (isImageFile(fileList.item(0))) {
    return imageData(fileList.item(0))
  }
  return null
}

const toDataAttributes = (obj) => (
  Object.keys(obj).reduce((all, key) => ({
    ...all, [`data-${key}`]: obj[key],
  }), {})
)

const inherit = ({ key, configs, exclude }) => {
  const found = configs.find((c) => (!isEmpty(c?.[key], ...Array(exclude).flat()) ? c[key] : null))
  return found ? found[key] : null
}

const sortKeys = (obj) => {
  const sorted = Object.keys(obj).sort().reduce((acc, key) => ({ ...acc, [key]: null }), {})
  return { ...sorted, ...obj }
}

export {
  isEqual,
  includeKeys,
  excludeKeys,
  filterEmptyValues,
  toSearchString,
  searchToObject,
  expandAria,
  imageData,
  isImageFile,
  imageFileSrc,
  toDataAttributes,
  copyObject,
  inherit,
  sortKeys,
  searchFilterObj,
  toOperatorParams,
}
export { default as deepMerge } from 'deepmerge'
