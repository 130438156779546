import React from 'react'
import PropTypes from 'prop-types'

import {
  isActive,
  ElementWrapper,
} from './helpers'
import { transformColor } from '../../../../helpers/color'
import { useDebounce } from '../../../../hooks/useDebounce'
import { useTemplate } from '../../useTemplate'
import { useSearchState } from '../../../../hooks/useSearchState'
/* import { useDebounce } from '../../../../hooks/useDebounce' */

import './edit-in-place.scss'

const PlaceholderText = ({ color, placeholder }) => (
  <span style={{ color: transformColor(color).set({ alpha: 0.3 }).toString() }}>{placeholder}</span>
)

const editableColor = ({ color }) => transformColor(color).set({ alpha: 0.05 }).toString()

const useDelayUpdate = () => {
  const { hooks: { updateElement } } = useTemplate()
  return useDebounce(updateElement, [updateElement], 500)
}

const EditableText = ({
  onChange, text, showPlaceholder, param,
}) => {
  const content = React.useRef(text)
  const [params, setParams] = useSearchState()

  const placeholderStyle = showPlaceholder ? {
    marginBottom: '-1.05em',
    position: 'absolute',
    inset: 0,
    zIndex: 1,
  } : {}

  const onInput = ({ target }) => {
    const value = target.innerText.trim()
    onChange(value)
  }

  React.useEffect(() => {
    if (params.editing === param) { setParams({}) }
  }, [])

  return (
    <span
      style={{
        whiteSpace: 'pre-wrap',
        ...placeholderStyle,
      }}
      onInput={onInput}
      onFocus={() => setParams({ editing: param })}
      onBlur={() => setParams({ editing: false })}
      contentEditable
      suppressContentEditableWarning
      tabIndex={0}
    >
      {content.current}
    </span>
  )
}

const Editable = ({
  configVar, children, ...props
}) => {
  const { element } = props
  const [showPlaceholder, setShowPlaceholder] = React.useState(!element.config[configVar])

  const update = useDelayUpdate()

  const onChange = React.useCallback((text) => {
    if (!!text === !!showPlaceholder) setShowPlaceholder(!text)
    const changes = { [configVar]: text || '' }
    update({ element, changes })
  }, [update, showPlaceholder])

  const active = isActive({ elementId: element.id })
  if (!active) {
    return children || <PlaceholderText {...props} />
  }

  return (
    <div className="level-edit-in-place" style={{ position: 'relative', background: editableColor(props) }}>
      <EditableText
        showPlaceholder={showPlaceholder}
        text={element.config[configVar]}
        onChange={onChange}
        param={configVar}
      />
      { showPlaceholder ? <PlaceholderText {...props} /> : null }
    </div>
  )
}

Editable.propTypes = {
  configVar: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired,
}

const EditInPlace = ({ children, ...props }) => {
  const { mode } = props

  // If not in builder mode, just return text
  if (!mode.match('builder')) { return children }

  return <Editable {...props}>{ children }</Editable>
}

EditInPlace.propTypes = {
  configVar: PropTypes.oneOf(['text', 'content']).isRequired,
  ...ElementWrapper.propTypes,
}

EditInPlace.defaultProps = ElementWrapper.defaultProps

const EditableCell = ({
  text,
  id,
  onChange: onChangeProp,
  children,
  wrapper,
  ...props
}) => {
  const { element } = props
  const active = isActive({ elementId: element.id })
  const update = useDelayUpdate()
  const [showPlaceholder, setShowPlaceholder] = React.useState(!text)

  const onChange = (value) => {
    if (!!value === !!showPlaceholder) setShowPlaceholder(!value)
    const changes = onChangeProp(value)
    update({ element, changes })
  }

  if (!active) {
    return children || <PlaceholderText {...props} />
  }

  const Tag = wrapper || 'div'

  return (
    <Tag className="level-edit-in-place" style={{ position: 'relative', background: editableColor(props), borderRadius: '3px' }}>
      <EditableText
        param={id}
        text={text}
        onChange={onChange}
        showPlaceholder={showPlaceholder}
      />
      { showPlaceholder ? <PlaceholderText {...props} /> : null }
    </Tag>
  )
}

EditableCell.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  text: PropTypes.string,
  wrapper: PropTypes.string,
  element: PropTypes.object.isRequired,
}

EditableCell.defaultProps = {
  wrapper: undefined,
  text: undefined,
  children: undefined,
}

const EditCellInPlace = ({ children, ...props }) => {
  const { mode } = props

  // If not in builder mode, just return text
  if (!mode.match('builder')) { return children }

  return <EditableCell {...props}>{ children }</EditableCell>
}

export {
  EditInPlace,
  EditableText,
  EditCellInPlace,
}
