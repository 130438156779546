import React from 'react'
import './divider.scss'
import PropTypes from 'prop-types'
import { validSizes, flushStyle } from '../../helpers'

const Divider = ({ flush, theme, style: styleProp }) => {
  const style = { ...styleProp, ...flushStyle({ flush }) }
  return <div className="level-divider" data-theme={theme} style={style} />
}

Divider.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.oneOf(['default', 'double']),
  flush: PropTypes.oneOf(validSizes),
}

Divider.defaultProps = {
  style: {},
  theme: 'default',
  flush: null,
}

export { Divider }
