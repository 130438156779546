import { theme } from './theme'

const confirmAccount = {
  name: 'Confirm Account',
  config: ({ color }) => {
    const {
      colors: {
        text,
        accentBg,
      },
      variables,
    } = theme({ color, defaultSubject: 'Confirm Your Account Email' })

    return {
      variables,
      sections: [
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '10px',
              name: 'Content',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Heading',
                    config: {
                      text: 'Confirm Your Account',
                      color: text,
                      padding: '0 0 0 0',
                      fontSize: '30px',
                      fontWeight: '600',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: 'Please take a moment to verify your email so we know it\'s you.\n\n\n[Confirm your email](https://enveloop.com)',
                      padding: '0 0 0 0',
                      fontSize: '14px',
                      fontWeight: '400',
                    },
                  },
                  {
                    type: 'Divider',
                    config: {
                      color: accentBg,
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      padding: '10px 0 10px 0',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: 'If you have trouble with the link above, copy and paste the URL below into your web browser.\n\n{{account_url}}',
                      padding: '0 0 0 0',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    }
  },
}

export { confirmAccount }
