import { theme } from './theme'

const orderConfirmation = {
  name: 'Order Confirmation',
  config: ({ color, Team = { name: 'CompanyName' } }) => {
    const {
      colors: {
        templateBg,
        accentBg,
        heading,
        text,
        textLight,
        accentColor,
        tableBorder,
      },
      variables,
    } = theme({ color, defaultSubject: 'Your order is confirmed' })

    return {
      variables,
      sections: [
        {
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '0px',
              backgroundColor: templateBg,
              name: 'Header',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Text',
                    config: {
                      content: Team.name,
                      color: accentColor,
                      align: 'center',
                      padding: '40px 0px 40px 0px',
                      fontSize: '24px',
                      fontWeight: '700',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '0px',
              name: 'Section Divider',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Divider',
                    config: {
                      borderStyle: 'solid',
                      borderWidth: '10px',
                      padding: '0 0 0 0',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          variables: {
            config: {
              name: 'Content',
              align: 'center',
              valign: 'top',
              padding: '15px 40px 45px 40px',
              elementSpacing: '15px',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Image',
                    config: {
                      src: `https://assets.enveloop.com/templates/gallery/confirm-header-${color}.png`,
                      width: '170',
                      padding: '20px 0px 20px 0px',
                    },
                  },
                  {
                    type: 'Heading',
                    config: {
                      fontSize: '44px',
                      text: 'Your order is confirmed',
                      color: heading,
                      padding: '10px 0px 10px 0px',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      padding: '0px 0px 0px 0px',
                      fontSize: '16px',
                      fontWeight: '400',
                      content: 'This email is your receipt for your purchase. No payment is due.',
                      color: text,
                    },
                  },
                  {
                    type: 'Table',
                    config: {
                      name: 'table',
                      tableConfig: {
                        columns: [
                          {
                            id: 'column1',
                            align: 'left',
                          },
                          {
                            id: 'column2',
                            align: 'left',
                          },
                        ],
                        body: {
                          rows: [
                            {
                              id: 'row1',
                              type: 'text',
                              columns: [
                                '{{invoice_date}}',
                                '{{billing_name}}',
                              ],
                            },
                            {
                              id: 'row-00127380-dcdd-4801-985e-4193b4687135',
                              columns: [
                                '{{invoice_number}}',
                                '{{billing_address}}',
                              ],
                              type: 'text',
                            },
                          ],
                          borders: {
                            horizontal: false,
                            vertical: false,
                            outerHorizontal: false,
                            outerVertical: false,
                          },
                          style: {
                            color: null,
                            borderColor: null,
                            backgroundColor: null,
                          },
                        },
                        header: {
                          rows: [],
                          borders: {
                            horizontal: false,
                            vertical: false,
                            outerHorizontal: false,
                            outerVertical: false,
                            match: true,
                          },
                          style: {
                            color: null,
                            borderColor: null,
                            backgroundColor: null,
                            fontWeight: 700,
                          },
                        },
                        footer: {
                          borders: {
                            horizontal: false,
                            vertical: false,
                            outerHorizontal: false,
                            outerVertical: false,
                            match: true,
                          },
                          rows: [],
                          style: {
                            color: null,
                            borderColor: null,
                            backgroundColor: null,
                            fontWeight: 400,
                          },
                        },
                      },
                      padding: '20px 0 0 0',
                      fontSize: '12px',
                      lineHeight: '120%',
                    },
                  },
                  {
                    type: 'Table',
                    config: {
                      name: 'table',
                      tableConfig: {
                        columns: [
                          {
                            id: 'column1',
                            align: 'left',
                          },
                          {
                            id: 'column2',
                            align: 'right',
                          },
                        ],
                        body: {
                          rows: [
                            {
                              id: 'row-16ddd0c8-3661-4a93-9586-bb4bc06a641f',
                              columns: [
                                '{{{name}}}',
                                '{{{price}}}',
                              ],
                              type: 'iterable',
                              html: '{{#products}}<tr><td align=\"left\" style=\"background-color:#F9F9F9;color:#454648;border-top:1px solid #DEDFE2;border-bottom:1px solid #DEDFE2\">{{{name}}}\n</td><td align=\"right\" style=\"background-color:#F9F9F9;color:#454648;border-top:1px solid #DEDFE2;border-bottom:1px solid #DEDFE2\">{{{price}}}\n</td></tr>{{/products}}',
                              variable: 'products',
                            },
                          ],
                          borders: {
                            horizontal: true,
                            vertical: false,
                            outerHorizontal: true,
                            outerVertical: false,
                          },
                          style: {
                            backgroundColor: accentBg,
                            borderColor: tableBorder,
                          },
                        },
                        header: {
                          rows: [
                            {
                              id: 'header1',
                              type: 'text',
                              columns: [
                                'Product',
                                'Price',
                              ],
                            },
                          ],
                          borders: {
                            horizontal: false,
                            vertical: false,
                            outerHorizontal: false,
                            outerVertical: false,
                            match: false,
                          },
                          style: {
                            backgroundColor: accentBg,
                            fontWeight: '700',
                            color: heading,
                          },
                        },
                        footer: {
                          borders: {
                            horizontal: false,
                            vertical: false,
                            outerHorizontal: false,
                            outerVertical: false,
                            match: false,
                          },
                          rows: [
                            {
                              id: 'row-e720edf4-d0d1-49b2-96e1-487e7b839279',
                              columns: [
                                'Payment Method:',
                                '{{{payment}}}',
                              ],
                              type: 'text',
                            },
                            {
                              id: 'row-8a90f3e4-fd64-42cf-97dd-cacb1d9fa4e9',
                              columns: [
                                '**Total:**',
                                '**{{{total}}}**',
                              ],
                              type: 'text',
                            },
                          ],
                          style: {
                            fontWeight: '400',
                            backgroundColor: accentBg,
                          },
                        },
                      },
                      padding: '25px 0px 25px 0px',
                      fontSize: '12px',
                      lineHeight: '180%',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: `Thank you for using ${Team.name}!\n${Team.name} Team`,
                      padding: '15px 0px 15px 0px',
                      fontSize: '14px',
                      lineHeight: '120%',
                      fontWeight: 400,
                      align: 'left',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '0px',
              name: 'Section Divider',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Divider',
                    config: {
                      color: templateBg,
                      borderStyle: 'solid',
                      borderWidth: '8px',
                      padding: '0 0 0 0',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '15px 40px 15px 40px',
              elementSpacing: '15px',
              name: 'Footer Link',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Button',
                    config: {
                      minWidth: '0%',
                      buttonPadding: '10px 45px 10px 45px',
                      padding: '30px 0px 0px 0px',
                      fontSize: '14px',
                      fontWeight: '600',
                      cornerStyle: 'round',
                      text: 'Download Receipt',
                      backgroundColor: accentColor,
                      url: '{{{receipt}}}',
                      align: 'center',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: 'If you have trouble with the button above, copy and paste<br /> the URL below into your web browser.\n\n{{receipt}}',
                      color: textLight,
                      align: 'center',
                      padding: '25px 0px 25px 0px',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '25px 0px 25px 0px',
              elementSpacing: '15px',
              backgroundColor: templateBg,
              name: 'Footer',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Text',
                    config: {
                      content: `© Copyright ${new Date().getFullYear()} ${Team.name}. All Rights Reserved.\n\n${Team.name}    |    123 Main St, Anytown, USA`,
                      color: textLight,
                      padding: '0 0 0 0',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    }
  },
}

export { orderConfirmation }
