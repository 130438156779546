import { theme } from './theme'

const userWelcome = {
  name: 'User Welcome',
  config: ({ color, Team = { name: 'CompanyName' }, Project = { name: 'ProductName' } }) => {
    const {
      colors: {
        text,
        accentBg,
      },
      variables,
    } = theme({ color, defaultSubject: 'Welcome {{{ name }}}!' })

    return {
      variables,
      sections: [
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '10px',
              name: 'Content',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Heading',
                    config: {
                      text: `Welcome to ${Team.name}`,
                      color: text,
                      padding: '0 0 0 0',
                      fontSize: '30px',
                      fontWeight: '600',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: `Thanks for signing up for ${Project.name}. We're excited for you to get started.\n\n\n[Go to your account](https://enveloop.com)`,
                      padding: '0 0 0 0',
                      fontSize: '14px',
                      fontWeight: '400',
                    },
                  },
                  {
                    type: 'Divider',
                    config: {
                      color: accentBg,
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      padding: '10px 0 10px 0',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: `For reference, here are your account details:


**Account URL:** {{account_url}}<br />\n**Username:** {{username}}


You've signed up for {{plan}} which bills \${{amount}} monthly. We'll send you a reminder before charging.


**Plan:** {{plan}}<br />
**Renewal Date:** {{renewal_date}}`,
                      padding: '0 0 0 0',
                      fontSize: '14px',
                      fontWeight: '400',
                    },
                  },
                  {
                    type: 'Divider',
                    config: {
                      color: accentBg,
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      padding: '10px 0 10px 0',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: 'If you have trouble with the link above, copy and paste the URL below into your web browser.\n\n{{account_url}}',
                      padding: '0 0 0 0',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    }
  },
}

export { userWelcome }
