import React from 'react'
import PropTypes from 'prop-types'

import { PopoverArrow, PopoverProvider, Popover } from '@ariakit/react'
import { Button } from '../Button'
import { Block } from '../Block'

import './popover.scss'

const PopoverWrapper = React.forwardRef((props, ref) => {
  const { placement, anchorRef, ...rest } = props
  const fallbackRef = React.useRef(null)
  return (
    <PopoverProvider placement={placement}>
      <span ref={fallbackRef} style={{ position: 'fixed' }} />
      <Popover
        ref={ref}
        open
        portal
        hideOnEscape
        getAnchorRect={() => {
          const anchorEl = anchorRef?.current || fallbackRef.current?.parentElement
          return anchorEl ? anchorEl.getBoundingClientRect() : null
        }}
        {...rest}
      />
    </PopoverProvider>
  )
})

const PopoverButton = ({
  content, popoverConfig, space, ...rest
}) => {
  const PopContent = React.useMemo(() => (typeof content === 'function' ? content : () => content), [])
  const [open, setOpen] = React.useState(false)
  const ref = React.useRef()

  const close = React.useCallback((opts = {}) => {
    const { timeout = 0 } = opts
    setTimeout(() => setOpen(false), timeout)
  }, [setOpen])

  return (
    <>
      <Button onClick={() => setOpen((o) => !o)} ref={ref} {...rest} />
      <PopoverWrapper {...popoverConfig} anchorRef={ref} open={open} onClose={() => setOpen(false)} className="level-popover">
        <PopoverArrow className="arrow" />
        <Block space={space}>
          <PopContent open={open} setOpen={setOpen} close={close} />
        </Block>
      </PopoverWrapper>
    </>
  )
}

PopoverButton.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  popoverConfig: PropTypes.object,
  space: Block.propTypes.space,
}

PopoverButton.defaultProps = {
  popoverConfig: {},
  space: 7,
}

export {
  PopoverButton,
}
