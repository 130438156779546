import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useQuery } from './useQuery'

export const useQueryProvider = ({
  query, // A string or function for use with useQuery
  onData, // Function to manipulate or respond to the data, return null or a transformed data object
  onError, // Function to respond to errors, return false to cancel updates
  onUpdate, // Function to execute once the call is complete
  contextVars, // An object (must be memoized!) to merge into the provider context vars
  autoRefresh = true,
  queryVars = {}, // An optional argument that will be provided to the query function
  useQueryFn = useQuery,
  clearDataOnFetch = false,
}) => {
  // hardRefresh tracks when a query data is stale becuase of a new query string
  // A refresh of the same query string does not need to re-render children immediately
  const [fetchData, _, getState] = useQueryFn(query)
  const {
    getData, getErrors, getIsLoading, getQueryCount,
  } = getState

  const refreshFn = React.useRef()
  refreshFn.current = (props = {}) => (
    fetchData({ ...queryVars, clearData: clearDataOnFetch, ...props })
  )

  const refresh = React.useCallback((...args) => refreshFn.current(...args), [])

  // Fetch when query string changes, any rendered content is out of date.
  const queryState = React.useMemo(() => (
    typeof query === 'function' ? query(queryVars) : query
  ), [query, queryVars])

  React.useEffect(() => {
    if (autoRefresh) { refresh() }
  }, [queryState])

  const hardRefresh = React.useCallback((props) => {
    refresh({ ...props, clearData: true })
  }, [refresh])

  const contextValue = React.useMemo(() => {
    // Make it possible to manipulate data before it hits the provider.
    // onData should be memoized and can be used to add hooks to a provider.
    // We pass refresh to onData as well to allow any hooks to trigger a refresh.
    let context = {
      refresh,
      hardRefresh,
      ...getState,
      ...contextVars,
    }

    if (getErrors() && onError) {
      onError({ data: getData(), errors: getErrors() })
      context.errors = getErrors()
    }

    if (getData()) {
      const data = {
        ...getData(),
        ...((onData && onData({ data: getData(), ...context })) || {}),
      }
      context = {
        ...context,
        ...data,
        data,
      }
    }

    return context
  }, [getData(), getErrors(), refresh, onData, contextVars])

  contextValue.isLoading = getIsLoading()
  contextValue.queryCount = getQueryCount()
  contextValue.errors = getErrors()

  React.useEffect(() => { if (onUpdate) onUpdate(contextValue) }, [contextValue])

  return contextValue
}

export const useInfiniteQueryProvider = ({
  query,
  queryVars,
  limit,
  rowVariable,
  rowAccessor,
  context,
  ...props
}) => {
  const offset = React.useRef(0)
  const items = React.useRef([])

  const contextValue = useQueryProvider({
    query, queryVars, ...props, autoRefresh: false,
  })
  // hardRefresh tracks when a query data is stale becuase of a new query string
  // A refresh of the same query string does not need to re-render children immediately

  // When query props change, this will set current offset to 0
  // This will force a reload by breaking the cache below
  React.useEffect(() => {
    items.current = []
    offset.current = 0
    contextValue.refresh({ limit, offset: offset.current })
  }, [query(queryVars)])

  const refresh = React.useCallback((args = {}) => {
    offset.current = 0
    items.current = []
    return contextValue.refresh({ ...args, limit, offset: offset.current })
  }, [contextValue.refresh])

  const hardRefresh = React.useCallback((args = {}) => {
    offset.current = 0
    items.current = []
    return contextValue.hardRefresh({ ...args, limit, offset: offset.current })
  }, [contextValue.hardRefresh])

  const getMore = React.useCallback((args = {}) => {
    if (!contextValue.getIsLoading()) {
      offset.current += limit
      contextValue.refresh({ ...args, limit, offset: offset.current })
    }
  }, [contextValue.refresh])

  const infiniteContextValue = React.useMemo(() => {
    const { data } = contextValue
    let rows = []
    if (typeof data === 'object') {
      if (Object.keys(data).length && rowAccessor) rows = rowAccessor(data)
      if (Object.keys(data).length && rowVariable) rows = data[rowVariable]
    }

    // Use getData for ternary because it returns null if empty
    items.current = contextValue.getData() ? [...items.current, ...rows] : items.current
    return {
      ...contextValue,
      hasMore: data ? rows?.length === limit : false,
      getMore,
      refresh,
      hardRefresh,
      items: items.current,
    }
  }, [contextValue])

  return infiniteContextValue
}

// This simplifies the common pattern of saving query data to a provider.
// The provider will pass through the data as well as a refresh function to trigger a new fetch.
const QueryProvider = ({
  context, // A React.createContext object
  children,
  element: Element,
  loading,
  infinite,
  useQueryFn,
  redirectOnError,
  ...rest
}) => {
  const useQueryProviderHook = infinite ? useInfiniteQueryProvider : useQueryProvider
  const contextValue = useQueryProviderHook({ ...rest, useQueryFn })

  // If data has not been fetched before, do not render children
  // NOTE: this is where we could render skeleton UI.
  if (!contextValue.getData()) {
    return loading || 'loading…'
  }

  if (redirectOnError && contextValue.errors) {
    return <Navigate to={redirectOnError} replace />
  }

  // Query providers can render an element (like children, but with props passed in)
  if (Element) return <Element {...contextValue} />

  // If using context, render a provider
  if (context) {
    return (
      <context.Provider value={contextValue}>
        { children }
      </context.Provider>
    )
  }
  return null
}

QueryProvider.propTypes = {
  context: PropTypes.object,
  query: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  queryProps: PropTypes.object,
  children: PropTypes.node,
  onData: PropTypes.func,
  onUpdate: PropTypes.func,
  vars: PropTypes.object,
  element: PropTypes.func,
  loading: PropTypes.node,
  infinite: PropTypes.bool,
  useQueryFn: PropTypes.func,
  redirectOnError: PropTypes.string,
}

QueryProvider.defaultProps = {
  vars: {},
  useQueryFn: useQuery,
  queryProps: undefined,
  context: undefined,
  onData: undefined,
  onUpdate: undefined,
  element: undefined,
  children: undefined,
  loading: undefined,
  infinite: undefined,
  redirectOnError: undefined,
}

export {
  QueryProvider,
}
