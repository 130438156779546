import React from 'react'
import { EmailTemplate } from './template'
import {
  jsxToString, filterEmptyValues,
  dotKeysToObject, sortKeys, deepMerge, includeKeys,
} from '../../../helpers'
import { newSection, exportSection } from './Section'

const renderEmailTemplate = ({ config, mode = 'render' }) => {
  const wrapper = jsxToString(<EmailTemplate config={config} type="email" mode={mode} />)
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 3.2//EN">${wrapper}`
}

const copyEmailTemplate = ({ config: templateConfig, ...rest }) => {
  const config = {
    ...templateConfig,
    sections: templateConfig.sections.map((section) => newSection({
      section,
      templateVariables: templateConfig.variables,
    })),
  }

  return {
    ...rest,
    config: {
      ...config,
      body: renderEmailTemplate({ config }),
    },
  }
}

const exportEmailTemplate = ({ config }) => ({
  variables: {
    ...config.variables,
    style: filterEmptyValues(config.variables.style),
  },
  sections: config.sections.map(exportSection),
})

// If vars appear to be nested in a conditional var, remove the conditional var from the prefix.
// For example, if `coupon` is a condtional var, and we have a template with this:
// {{#coupon}}
//   {{ coupon }}
//   {{ code }}
// {{/coupon}}
// We'd have the following vars: `coupon`, `coupon.coupon` and `coupon.code`
// This function removes the coupon prefix so we have `coupon` and `code` instead
const fixConditionalVars = ({ vars, conditional }) => (
  conditional?.length ? vars.map((v) => {
    const match = conditional.find((c) => v.startsWith(`${c}.`))
    return match ? v.replace(`${match}.`, '') : v
  }) : vars
)

// Iterable vars should have child vars in an array instead of just object children.
// Example, if `items` is an iterable var, an object with this shape:
// items: {
//   name: 'product',
//   price: '$10.00',
// }
// Will be converted to:
// items: [
//   {
//     name: 'product',
//     price: '$10.00',
//   },
// ]

const fixIterableVars = ({ vars, iterable }) => (
  iterable?.length ? Object.keys(vars).reduce((acc, key) => {
    if (iterable.includes(key)) {
      if (acc[key] && !Array.isArray(acc[key])) {
        acc[key] = [acc[key]]
      }
    }
    return acc
  }, vars) : vars
)

const getEmailTemplateVars = ({ template, previewVars = {}, config = 'previewConfig' }) => {
  const { conditional, iterable } = EmailTemplate.getMustacheTypes({ template, config }) || {}
  // Merge user vars pulled from templates with any values in the editor
  const cond = fixConditionalVars({ vars: template?.previewTemplateVariables || [], conditional })
  const obj = dotKeysToObject(cond)
  const sortedVars = previewVars
    ? sortKeys(deepMerge(obj, includeKeys(previewVars, obj)))
    : sortKeys(obj)
  return fixIterableVars({ vars: sortedVars, iterable })
}

export {
  renderEmailTemplate,
  copyEmailTemplate,
  exportEmailTemplate,
  getEmailTemplateVars,
}
