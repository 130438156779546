import React from 'react'
import PropTypes from 'prop-types'

import {
  includeKeys, jsxToString, renderMustache, renderString,
} from '../../../../../helpers'
import { Cell, DataCell } from './cell'
import { useTemplate } from '../../../useTemplate'

// This will render to a string and then render content with
// client mustache variables (if present)
// This also avoids errors with iterable tags in the table root

const Preview = ({ content, Tag }) => {
  const { previewVars } = useTemplate()
  const preview = renderMustache(jsxToString(content), previewVars)
  return <Tag>{renderString(preview)}</Tag>
}

Preview.propTypes = {
  content: PropTypes.node.isRequired,
  Tag: PropTypes.string.isRequired,
}

const Row = (props) => {
  const {
    row,
    mode,
    varPath,
    rowGroup,
    rowIndex,
    color,
    element: { config },
  } = props

  const { tableConfig } = config
  const { body, columns } = tableConfig
  const { style, borders } = tableConfig[rowGroup]

  const borderConfig = borders.match ? body.borders : borders

  const styleConfig = includeKeys({ ...props, ...style, postion: 'relative' }, style)
  // Inherit text color from body color or element color
  styleConfig.color ||= body.color || color
  // Inherit border color from body border color or element color
  styleConfig.borderColor ||= body.style.borderColor || body.style.color || color

  const cellProps = {
    ...props, styleConfig, borderConfig, columns,
  }

  if (mode.match(/render/)) {
    return `{{{ ${varPath}.tableConfig.${rowGroup}.rows.${rowIndex}.html }}}`
  }

  return (
    <>
      <DataCell start {...cellProps} />
      <tr>{
        columns.map((column, columnIndex) => (
          <Cell key={`${column.id}-${row.id}`} {...cellProps} columnIndex={columnIndex} />
        ))
      }
      </tr>
      <DataCell {...cellProps} />
    </>
  )
}

Row.propTypes = {
  element: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  rowGroup: PropTypes.oneOf(['header', 'footer', 'body']).isRequired,
  mode: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
  varPath: PropTypes.string.isRequired,
  color: PropTypes.string,
}

Row.defaultProps = {
  color: undefined,
}

const Rows = (props) => {
  const {
    element: { config }, rowGroup, mode,
  } = props
  const { columns } = config.tableConfig
  const { rows } = config.tableConfig[rowGroup]

  const Tag = {
    header: 'thead',
    footer: 'tfoot',
    body: 'tbody',
  }[rowGroup]

  if (!rows.length) return null

  const rowContent = rows.map((row, rowIndex) => (
    <Row
      key={row.id}
      row={row}
      rowIndex={rowIndex}
      columns={columns}
      /* borders={borderConfig} */
      /* styleConfig={styleConfig} */
      {...props}
    />
  ))

  if (mode.match(/preview/)) {
    return <Preview content={rowContent} Tag={Tag} />
  }

  return (
    <Tag>
      { rowContent }
    </Tag>
  )
}

Rows.propTypes = {
  rowGroup: PropTypes.oneOf(['header', 'footer', 'body']).isRequired,
  element: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
}

export {
  Rows,
  Row,
}
