import React from 'react'
import PropTypes from 'prop-types'

import { useParams } from 'react-router-dom'

import {
  Table, TruncateCell,
  useVirtualTable, useInfiniteFetch,
  formatDateTime,
} from '@level'

import { useErrorModal } from './modal'
import { useErrorLog } from './provider'
import {
  DateFilterHeader,
  ErrorFilterHeader,
  SdkVersionFilterHeader,
} from './filters'

import { ErrorNameCode } from './helpers'

const DateCell = ({ getValue }) => (
  <TruncateCell getValue={() => formatDateTime(getValue(), { utc: true })} />
)

const NameCell = ({ getValue }) => (
  <ErrorNameCode error={getValue()} />
)

const ErrorsTable = ({ selectItem, onScroll }) => {
  const { env } = useParams()
  const {
    items: errors, hasMore, getMore, isLoading,
  } = useErrorLog()
  const hadErrors = React.useRef(errors?.length)

  const columnNames = React.useMemo(() => [
    { error: { width: '30%', cell: NameCell, header: ErrorFilterHeader } },
    'path',
    { sdkVersion: { header: SdkVersionFilterHeader } },
    { createdAt: { header: DateFilterHeader, cell: DateCell } },
  ], [errors])

  const table = useVirtualTable({
    columnNames,
    enableRowSelection: true,
    singleRowSelection: true,
    hasMore,
    data: errors,
  })

  useErrorModal({ table })

  const selectedItem = table.getSelectedRowModel().rows[0]?.original
  const { selected, selectedRow, deselectAll } = table.getSelection(table)
  React.useEffect(() => {
    if (selectedRow) { deselectAll() }
  }, [env])

  React.useEffect(() => {
    selectItem(selectedItem)
  }, [selected])

  useInfiniteFetch({
    hasMore, getMore, virtualizer: table.virtualizer, isLoading, items: errors,
  })

  return (
    <div>
      { hadErrors ? (
        <Table
          table={table}
          onScroll={onScroll}
          theme="white"
          limitHeight
          offsetSpace={6}
          className="message-log-items"
        />
      ) : null }
      { errors.length === 0 ? <h1>No Errors</h1> : null }
    </div>
  )
}

ErrorsTable.propTypes = {
  selectItem: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
}

ErrorsTable.defaultProps = {
  onScroll: undefined,
}

export {
  ErrorsTable,
}
