import React from 'react'
import {
  Shelf, Stack, RadioInputWrapper, P, Block, Icon,
} from '@level'

import './gallery.scss'

export { EmailTemplateGallery } from './email'
export { SMSTemplateGallery } from './sms'

const TemplateGallery = () => (
  <div className="theme-gallery" style={{ display: 'flex' }}>
    <div className="theme-gallery-main" style={{ margin: '5vh auto 7vh', textAlign: 'center' }}>
      <Stack gap={8}>
        <P>Select a type of message to add.</P>
        <Shelf gap={4}>
          <RadioInputWrapper className="theme-gallery-email" name="type" value="email" label="Email" theme="card" defaultChecked>
            <Block space={[7, 10]}>
              <Stack gap={4}>
                <Icon name="envelope-solid" size={6} />
                Email
              </Stack>
            </Block>
          </RadioInputWrapper>
          <RadioInputWrapper className="theme-gallery-sms" name="type" value="sms" label="SMS" theme="card">
            <Block space={[7, 10]}>
              <Stack gap={4}>
                <Icon name="message-lines-solid" size={6} />
                SMS
              </Stack>
            </Block>
          </RadioInputWrapper>
        </Shelf>
      </Stack>
    </div>
  </div>
)

export {
  TemplateGallery,
}
