import React from 'react'
import PropTypes from 'prop-types'
import {
  Menu,
  MenuButton as ReachMenuButton,
} from '@reach/menu-button'

import { Divider } from '../Divider'
import { Button } from '../Button'
import { MenuLink } from './MenuLink'
import { MenuItem, MenuItems } from './MenuItem'
import { MenuPopover } from './MenuPopover'

import '@reach/menu-button/styles.css'
import './menu-button.scss'

const MenuButton = ({ items, ...rest }) => {
  const position = React.useCallback((button, popover, defaults) => ({
    ...defaults,
    minWidth: button.width,
  }), [])

  return (
    <Menu>
      <Button
        as={ReachMenuButton}
        className={`level-menu-button level-select-nav-button ${rest.className || ''}`}
        theme="menu"
        {...rest}
      />
      <MenuPopover position={position}>
        <MenuItems>
          { items.map(({
            text, onSelect, divider, key, ...props
          }) => {
            const MenuEl = onSelect ? MenuItem : MenuLink
            return (
              <React.Fragment key={key || text}>
                { divider ? <Divider /> : null }
                <MenuEl text={text} onSelect={onSelect} {...props} />
              </React.Fragment>
            )
          })}
        </MenuItems>
      </MenuPopover>
    </Menu>
  )
}

const StateMenuButton = ({
  options, defaultValue, onChange, ...props
}) => {
  const defaultOption = options.find(({ value }) => value === defaultValue)
  const [buttonText, setButtonText] = React.useState(defaultOption?.children || defaultOption?.label || 'Select One')

  const items = options.map(({ value, label: text = value, ...rest }) => ({
    key: value,
    text,
    onSelect: () => {
      if (onChange) onChange(value)
      setButtonText(text)
    },
    ...rest,
  }))
  return (
    <MenuButton
      theme="menu"
      items={items}
      text={buttonText}
      iconAfter="angle-up-and-down"
      grow
      {...props}
    />
  )
}

StateMenuButton.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any,
    value: PropTypes.string,
  })).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

StateMenuButton.defaultProps = {
  onChange: undefined,
  defaultValue: undefined,
}

MenuButton.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    onSelect: PropTypes.func,
    to: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  })).isRequired,
}

export {
  Menu,
  MenuButton,
  StateMenuButton,
}
