import React from 'react'
import PropTypes from 'prop-types'

import { useParams } from 'react-router-dom'
import {
  QueryProvider, useProject, useQuery,
} from '@app/hooks'

import {
  useNetworkStatus,
  useUuid, useChannel,
  filterEmptyValues,
} from '@level'

import {
  useRenameTemplate,
  useCopyTemplate,
  useDeleteTemplate,
  usePublishTemplate,
  useRevertTemplate,
} from './hooks'

import {
  templateQuery,
  updateTemplateQuery,
} from './queries'

const TemplateContext = React.createContext({})
const useTemplate = () => React.useContext(TemplateContext)

const TemplateTestContext = React.createContext()
const useTemplateTest = () => React.useContext(TemplateTestContext)

// This encapsulates update and delete queries to isolate the re-rendering.
// Without this, the useQuery hook triggers multiple cascading re-renders.
// But since the template isn't fetching data, these are unnecessary.
const TemplateQueries = () => {
  const { refresh, channel, getData } = useTemplate()
  const unpublishedChanges = React.useRef(false)
  const [update] = useQuery(updateTemplateQuery)
  const networkStatus = useNetworkStatus()

  const pushUpdate = React.useCallback(async ({ previewConfig }) => {
    const isOffline = !window.navigator.onLine && !process.env.ALLOW_OFFLINE
    const { template: { id, published } } = getData()

    // Track when unpublished changes exist
    unpublishedChanges.current = isOffline
    // NoOp if offline
    if (isOffline) return

    await update(filterEmptyValues({ id, previewConfig }))

    // If template was published, refresh to enable the UI to reflect changes since last publish
    if (published) { await refresh() }
  }, [])

  React.useEffect(() => {
    (async () => {
      if (networkStatus.connected && unpublishedChanges.current) {
        await pushUpdate(getData().template)
      }
    })()
  }, [networkStatus])

  useChannel(channel.name, async ({ data }) => {
    if (data.update?.config) {
      const { config: previewConfig } = data.update
      pushUpdate({ previewConfig })
    }
  })

  return null
}

const TemplateProvider = ({ children }) => {
  const { Project, url: projectUrl } = useProject()
  const { templateId } = useParams()

  const redirect = ({ data }) => (
    projectUrl(`templates/template/${data.template.slug}`)
  )

  const deleteTemplate = useDeleteTemplate({ redirect: '../../' })
  const renameTemplate = useRenameTemplate({ redirect })
  const copyTemplate = useCopyTemplate({ redirect })
  const revertTemplate = useRevertTemplate({ redirect: './' })
  const publishTemplate = usePublishTemplate()

  const channel = useChannel(`template/${templateId}-${useUuid()}`)

  const onData = React.useCallback(({ data: { template }, refresh }) => ({
    deleteTemplate: () => deleteTemplate({ data: template }),
    renameTemplate: () => renameTemplate({
      data: template,
      hooks: {
        // if slug is the same, refresh. If slug has changed navigation will trigger the refresh
        onSuccess: ({ formData: { slug } }) => {
          if (slug === template.slug) { refresh() }
        },
      },
    }),
    copyTemplate: () => copyTemplate({ data: template }),
    revertTemplate: () => revertTemplate({ data: template, hooks: { onSuccess: refresh } }),
    publishTemplate: () => publishTemplate({ data: template, hooks: { onSuccess: refresh } }),
    channel,
  }), [templateId])

  return (
    <QueryProvider
      context={TemplateContext}
      query={templateQuery({ slug: templateId, Project })}
      onData={onData}
    >
      <TemplateQueries />
      { children }
    </QueryProvider>
  )
}

TemplateProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  useTemplate,
  TemplateContext,
  TemplateProvider,
  useTemplateTest,
  TemplateTestContext,
  updateTemplateQuery,
}
