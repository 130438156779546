import React from 'react'
import PropTypes from 'prop-types'

import { Stack } from '../Stack'
import { HiddenInput } from './HiddenInput'
import { useFormRef } from '../../hooks/useForm'
import { useThrottle } from '../../hooks/useThrottle'
import { useUuid } from '../../hooks/useUuid'
import { camelToTitle, transformColor, validateColor } from '../../helpers'

// 'fullName' => 'Full Name'
const deriveLabel = ({ label, name }) => (label === true ? camelToTitle(name) : label) || ''

const ColorInput = ({
  label, name, defaultValue, placeholder, onSelectColor,
}) => {
  const { setValue, onChangeInput } = useFormRef()
  const colorToValue = (color) => transformColor(color)?.hex

  const testRef = React.useRef() // Ref for validating color entry
  const swatchRef = React.useRef() // Ref for setting swatch colors
  const textInputRef = React.useRef() // Ref for user text input
  const colorValue = React.useRef(colorToValue(defaultValue)) // Tracking current color value

  const labelText = deriveLabel({ label, name })
  const colorInputId = `color-${useUuid()}`
  const textInputId = `input-${useUuid()}`

  const setColorValue = (value) => {
    swatchRef.current.style.backgroundColor = value
    setValue(name, value, { shouldDirty: value !== colorValue.current })
    if (onChangeInput) onChangeInput({ [name]: value })
    if (onSelectColor) onSelectColor({ color: value })
    colorValue.current = value
  }

  // Update color swatches and input values
  const onChange = useThrottle(({ target }) => {
    const validColor = validateColor({ color: target.value, elementRef: testRef })
    if (validColor) setColorValue(colorToValue(validColor))
    if (target.value === '') setColorValue(null)

    // If input change is not from user text input, update text input with value
    if (target !== textInputRef.current) {
      textInputRef.current.value = colorToValue(target.value)
    }
  }, [], 12)

  // When bluring, set text input to last color value
  // If a user types in a value like `#000`, this will correct it to `#000000` on blur
  const onBlur = (e) => {
    e.target.value = colorValue.current
  }

  const input = (
    <div className="level-color-input">
      <label
        className="level-color-swatch-wrapper"
        htmlFor={colorInputId}
        style={{ cursor: 'pointer' }}
      >
        <div>
          {/* This is the input the form sees, which is programatically updated by user controls */}
          <HiddenInput name={name} defaultValue={defaultValue} />
          {/* This div is used to validate color entries */}
          <div ref={testRef} />
          {/* Browser native color input */}
          <input
            id={colorInputId}
            defaultValue={defaultValue}
            name="colorswatch"
            type="color"
            onChange={onChange}
          />
          <div
            className="level-color-input-swatch"
            style={{ backgroundColor: defaultValue || null, position: 'absolute', inset: 0 }}
            ref={swatchRef}
          />
        </div>
      </label>
      {/* Input for user text entry */}
      <input
        className="level-input"
        id={textInputId}
        type="text"
        defaultValue={defaultValue}
        onInput={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        ref={textInputRef}
        spellCheck="false"
      />
    </div>
  )

  return (
    <Stack gap={4}>
      { label ? <label className="level-label" htmlFor={textInputId}>{ labelText }</label> : null }
      { input }
    </Stack>
  )
}

export {
  ColorInput,
}

ColorInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  placeholder: PropTypes.string,
  onSelectColor: PropTypes.func,
}

ColorInput.defaultProps = {
  defaultValue: '',
  label: true,
  placeholder: 'none',
  onSelectColor: undefined,
}
