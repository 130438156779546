import React from 'react'
import PropTypes from 'prop-types'
import { Block } from '../../../../Block'
import { Divider } from '../../../../Divider'
import { Text } from '../../../../Text'
import { Grid } from '../../../../Grid'
import { Stack } from '../../../../Stack'
import { Input } from '../../../../Input'
import { RadioPanel } from '../../../../RadioPanel'
import { Form } from '../../../../Form'
import { Card } from '../../../../Card'

import { copyObject, excludeKeys, includeKeys } from '../../../../../helpers/object'

import { useSection } from '../helpers'
import { useTemplateConfig } from '../useTemplateConfig'
import {
  configDefaults, configInputLayout, newColumnConfig,
  sectionTypes,
} from '../defaults'

import { ConfigForm } from '../Form'

const sectionTypeLabels = {
  normal: 'None',
  iterable: 'Iterable',
  conditional: 'Conditional',
}

const SectionType = ({ section, updateSection }) => {
  const currentType = section.variables.config.type
  const onChangeType = ({ type, variable }) => {
    const newSection = copyObject(section)
    // The input passes back proportions separated by columns.
    // `100%` is a single column. `50%:50%` is two columns, `33%:34%:33%` is three columns, etc
    // By splitting on the colon we can get the widths, and the number of columns.
    if (type) {
      newSection.variables.config.type = type
    }
    if (variable) {
      newSection.variables.config.variableStart = `{{#${variable}}}`
      newSection.variables.config.variableEnd = `{{/${variable}}}`
    }
    updateSection({ config: newSection })
  }
  const types = React.useMemo(() => (
    sectionTypes.map((o) => ({ value: o, label: sectionTypeLabels[o] }))
  ), [])

  const defaultVariable = React.useMemo(() => (
    (section.variables.config.variableStart || '').replace(/[}{#]/g, '')
  ), [])
  const desc = (() => {
    if (currentType === 'iterable') return 'This section repeats for each item in this variable.'
    if (currentType === 'conditional') return 'Render this section if this variable is present.'
    return ''
  })()

  return (
    <Form onChangeInput={onChangeType}>
      <Stack gap={6}>
        <RadioPanel label="Section Data" name="type" defaultValue={currentType} items={types} />
        { section.variables.config.type !== 'normal' ? (
          <Card space={5}>
            <Input
              name="variable"
              defaultValue={defaultVariable}
              label="Section Variable"
              descriptionAfter={desc}
            />
          </Card>
        ) : null }
      </Stack>
    </Form>
  )
}

const SectionColumns = ({ section, updateSection }) => {
  const { id, columns } = section.variables
  const { length: columnCount } = columns
  const columnLayout = columns.map(({ config }) => (config.width || `${100.0 / columnCount}%`)).join(':')
  const columnConfig = { columnLayout }

  const onChangeColumns = (config) => {
    const newSection = copyObject(section)
    const { columns: newColumns } = newSection.variables
    // The input passes back proportions separated by columns.
    // `100%` is a single column. `50%:50%` is two columns, `33%:34%:33%` is three columns, etc
    // By splitting on the colon we can get the widths, and the number of columns.
    const widths = config.columnLayout.split(':')

    for (let toRemove = 0; toRemove < (columns.length - widths.length); toRemove += 1) {
      // Remove last column
      const { elements } = newColumns.pop()
      // If there are elements in that column
      if (elements.length) {
        // Splice the elements into the last remaining column's list of elements
        newColumns[newColumns.length - 1].elements.splice(
          newColumns[newColumns.length - 1].elements.length,
          0,
          ...elements,
        )
      }
    }

    // If columns were added, add a new empty column configuration
    for (let toAdd = 0; toAdd < (widths.length - columns.length); toAdd += 1) {
      newColumns.push(newColumnConfig())
    }

    // set new widths for columns
    widths.forEach((width, index) => {
      newColumns[index].config.width = width
    })

    newSection.variables.columns = newColumns

    updateSection({ config: newSection })
  }

  return (
    <ConfigForm
      id={`${id}-columns`}
      config={columnConfig}
      onChange={onChangeColumns}
      reset={id}
    />
  )
}

SectionColumns.propTypes = {
  updateSection: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
}

const SectionConfigForm = ({ section, updateSection }) => {
  const onConfigChange = (config) => {
    const newSection = copyObject(section)
    newSection.variables.config = {
      ...newSection.variables.config,
      ...includeKeys(config, configDefaults.section),
    }

    updateSection({ config: newSection, updateVars: true })
  }

  const { config } = section.variables
  let sectionConfig = { ...configDefaults.section, ...config }
  if (section.variables.columns.length < 2) {
    sectionConfig = excludeKeys(sectionConfig, ['valign', 'columnSpace'])
  }

  const configLayout = configInputLayout('section', section)

  return (
    <>
      <Stack gap={6} space={[7, 7, 0, 7]}>
        <SectionType section={section} updateSection={updateSection} />
        <SectionColumns section={section} updateSection={updateSection} />
      </Stack>
      <ConfigForm
        id={`${section.variables.id}-style`}
        reset={section.variables.id}
        config={sectionConfig}
        onChange={onConfigChange}
        configLayout={configLayout}
        space={7}
      />
    </>
  )
}

SectionConfigForm.propTypes = {
  section: PropTypes.shape({
    variables: PropTypes.object.isRequired,
  }).isRequired,
  updateSection: PropTypes.func.isRequired,
  sectionConfig: PropTypes.object,
}

SectionConfigForm.defaultProps = {
  sectionConfig: {},
}

const SectionConfig = () => {
  const section = useSection()
  const { updateSection } = useTemplateConfig()

  return (
    <Grid templateRows="min-content min-content min-content min-content auto" align="normal" maxHeight>
      <Block space={7}>
        <Text tag="h3">Section</Text>
      </Block>
      <Divider />
      <SectionConfigForm
        section={section}
        updateSection={updateSection}
      />
    </Grid>
  )
}

export {
  SectionConfig,
  SectionConfigForm,
}
