import { configDefaults } from './Config/defaults'
import { newSection } from './Section'
import { renderEmailTemplate } from './helpers'

const newEmailTemplate = (options = {}) => {
  const { sections = [] } = options

  const template = {
    sections: sections.map((section) => (
      newSection({ section, templateVariables: configDefaults.template })
    )),
    variables: {
      ...configDefaults.template,
      defaults: {},
    },
  }
  return { config: { ...template, body: renderEmailTemplate({ config: template }) } }
}

export {
  newEmailTemplate,
}
