import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '../../../Text'
import { Block } from '../../../Block'
import { DataStack, DataShelf } from '../../../DataStack'
import { useTemplateConfig } from './useTemplateConfig'
import { Form } from '../../../Form'
import { Input, Textarea } from '../../../Input'
import { SubmitButton } from '../../../Button'
import { ButtonShelf } from '../../../ButtonShelf'
import { Stack } from '../../../Stack'
import { Grid } from '../../../Grid'
import { useMessages } from '../../../../hooks/useMessages'
import { Link } from '../../../Link'
import { renderMustache } from '../../../../helpers/mustache'
import { toMailNameAddress, getMailNameAddress } from '../../../../helpers/text'

const QuickEdit = ({
  name, setEdit, config, placeholder, truncate, mode,
  focus = name,
  previewVars,
  ...rest
}) => {
  const placeholderText = mode === 'builder' ? `[${placeholder[name]}]` : 'none'

  if (mode === 'preview') {
    return config[name]
      ? renderMustache(config[name], previewVars)
      : placeholderText
  }

  const text = config[name] || placeholderText

  return (
    // color={!config[name] && !providerConfig[name] ? 'neutral-400' : 'neutral-800'}
    <Text truncateLines={truncate} style={{ fontWeight: !config[name] ? null : 500 }}>
      {setEdit ? (
        <Link
          theme="quick-edit"
          tag="span"
          onClick={() => setEdit(focus)}
          onFocus={() => setEdit(focus)}
          role="button"
          text={text}
          style={{ display: 'inline' }}
          {...rest}
        />
      ) : text}
    </Text>
  )
}

QuickEdit.propTypes = {
  name: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  placeholder: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  truncate: PropTypes.any,
  setEdit: PropTypes.func,
}

QuickEdit.defaultProps = {
  truncate: null,
  setEdit: null,
}

const TemplateConfigEmailPreview = (props) => {
  // TODO: toggle truncation to see the full text of a preview during test mode
  const { expanded, setExpanded } = React.useState(false)
  const defaults = {
    ...props, truncate: expanded ? null : 1,
  }

  return (
    <DataStack gap={4}>
      <DataShelf title="From">
        <QuickEdit {...defaults} name="defaultFrom" focus="fromName" title="Edit From" />
      </DataShelf>
      <DataShelf title="Subject">
        <QuickEdit {...defaults} name="defaultSubject" title="Edit Subject" />
      </DataShelf>
      <DataShelf title="Preview Text">
        <QuickEdit {...defaults} name="previewText" title="Edit Preview Text" />
      </DataShelf>
    </DataStack>
  )
}

const TemplateConfigEmailForm = ({
  config, onSubmit, onCancel, placeholder, edit,
}) => {
  const { name, address } = getMailNameAddress(config.defaultFrom)
  return (
    <Form onSubmit={onSubmit} focus={edit}>
      <Stack gap={5}>
        <DataStack gap={4}>
          <DataShelf title="From" style={{ paddingTop: '.75em' }}>
            <Grid columns={2} gap={5}>
              <Input
                name="fromName"
                defaultValue={name}
                placeholder={placeholder.defaultFromName}
                pattern="[^<>]+$"
                label={false}
              />
              <Input
                name="fromAddress"
                defaultValue={address}
                placeholder={placeholder.defaultFromAddress}
                label={false}
              />
            </Grid>
          </DataShelf>
          <DataShelf title="Subject" style={{ paddingTop: '.75em' }}>
            <Input
              name="defaultSubject"
              label={false}
              placeholder={placeholder.defaultSubject}
              defaultValue={config.defaultSubject}
            />
          </DataShelf>
          <DataShelf title="Preview Text" style={{ paddingTop: '.75em' }}>
            <Textarea
              name="previewText"
              label={false}
              placeholder={placeholder.previewText}
              defaultValue={config.previewText}
            />
          </DataShelf>
        </DataStack>
        <ButtonShelf onCancel={onCancel}>
          <SubmitButton text="Save Changes" />
        </ButtonShelf>
      </Stack>
    </Form>
  )
}

TemplateConfigEmailForm.propTypes = {
  config: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  placeholder: PropTypes.object.isRequired,
  edit: PropTypes.string.isRequired,
}

const TemplateConfigEmailSettings = ({
  config, mode, previewVars, teamDefaultFrom,
}) => {
  const { updateTemplate } = useTemplateConfig()

  const [edit, setEdit] = React.useState(false)
  const { publish } = useMessages({ channel: 'layout' })
  const teamDefault = getMailNameAddress(teamDefaultFrom)

  const placeholder = {
    defaultFromName: teamDefault.name ? teamDefault.name : 'From Name',
    defaultFromAddress: teamDefault.address ? `${teamDefault.address} (team default)` : 'from@email.com',
    defaultFrom: teamDefaultFrom || 'From Name <from@email.com>',
    defaultSubject: 'Choose a subject',
    previewText: 'Add text for email preview',
  }

  React.useEffect(() => {
    publish({ refresh: {} })
  }, [edit])

  const onSubmit = React.useCallback(async ({ data }) => {
    const { fromName, fromAddress, ...rest } = data

    if (fromAddress) {
      rest.defaultFrom = toMailNameAddress({ name: fromName, address: fromAddress })
    }

    const newConfig = { ...config, variables: { ...config.variables, config: rest } }
    await updateTemplate({ config: newConfig })
    setEdit(false)
  }, [updateTemplate])

  if (!config) return null

  return (
    <Block space={[7, 8]} className="level-template-config-header">
      { updateTemplate && edit
        ? (
          <TemplateConfigEmailForm
            config={config.variables.config}
            onSubmit={onSubmit}
            placeholder={placeholder}
            onCancel={() => setEdit(false)}
            edit={edit}
          />
        ) : (
          <TemplateConfigEmailPreview
            config={{
              ...config.variables.config,
              defaultFrom: config.variables.config.defaltFrom || teamDefaultFrom,
            }}
            placeholder={{ ...placeholder, defaultFrom: teamDefaultFrom || 'From Name <from@email.com>' }}
            setEdit={updateTemplate ? setEdit : null}
            mode={mode}
            previewVars={previewVars}
          />
        )}
    </Block>
  )
}

TemplateConfigEmailSettings.propTypes = {
  config: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
}

export {
  TemplateConfigEmailSettings,
}
