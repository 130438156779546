import React from 'react'
import PropTypes from 'prop-types'
import { ElementWrapper } from './helpers'
import { useTemplate } from '../../useTemplate'

const measureImg = ({
  id, setImageHeights, setHeight, src,
}) => {
  const img = window.document?.querySelector(`#${id} img`)
  if (img?.height || !img?.clientHeight) {
    window.setTimeout(() => measureImg({
      id, setImageHeights, setHeight, src,
    }), 10)
  } else if (img) {
    const imgHeight = `${img.clientHeight}px`
    setImageHeights((h) => ({ ...h, [src]: imgHeight }))
    setHeight(imgHeight)
  }
}

const Image = (props) => {
  const { hooks } = useTemplate()
  const {
    element,
    element: { config },
    mode,
    varPath,
  } = props

  const {
    src,
    alt,
    align,
    width,
    padding,
    backgroundColor,
    ...configStyle
  } = config

  const [height, setHeight] = React.useState(config.height || hooks?.imageHeights?.[src])
  const placeholder = !src && mode.match('builder')

  const style = {
    ...Image.style,
    ...configStyle,
  }

  const builder = mode.includes('builder')
  const srcVar = builder ? config.src : `{{ ${varPath}.src }}`
  const altVar = builder ? config.alt : `{{ ${varPath}.alt }}`
  const hrefVar = builder ? config.href : `{{ ${varPath}.href }}`

  React.useEffect(() => {
    if (mode.match(/builder/)) {
      // In Safari when an image is re-rendered it is sometimes takes a while to load
      // if an image has no height it will cause a shift in the template rendering
      // So we cache the height of the image.
      // This also helps prevent disruptions while dragging elements in the builder.
      if (!config.height && window) {
        window.setTimeout(() => measureImg({
          id: element.id,
          setImageHeights: hooks.setImageHeights,
          setHeight,
          src,
        }), 0)
      }
    }
  }, [config.height, src])

  const image = placeholder ? (
    <div
      className="enveloop-element-image-placeholder"
      style={{
        ...style,
        width,
        height,
        display: 'grid',
      }}
      aria-label="image placeholder"
    />
  ) : (
    <img
      className="enveloop-element-image"
      src={srcVar}
      alt={altVar}
      width={width}
      height={height}
      style={style}
    />
  )

  return (
    <ElementWrapper {...props} style={{ padding, backgroundColor }}>
      { hrefVar && !mode.match('builder') ? (
        <a className="image-element-link" href={hrefVar}>
          { image }
        </a>
      ) : image }
    </ElementWrapper>
  )
}

Image.propTypes = {
  ...ElementWrapper.propTypes,
  varPath: PropTypes.string.isRequired,
}

Image.defaultProps = ElementWrapper.defaultProps

// Default inline styling goes here
Image.style = {
  maxWidth: '100%',
  display: 'block',
}
Image.type = 'Image'

// User configurable variables
Image.config = {
  src: '',
  alt: '',
  href: '',
  width: '300px',
  height: null,
  backgroundColor: null,
  align: '',
  padding: '0 0 0 0',
}

Image.configLayout = [
  'src',
  'href',
  'alt',
  '---',
  'width',
  'height',
  'backgroundColor',
  '---',
  'align',
  'padding',
]

export { Image }
