import React from 'react'
import PropTypes from 'prop-types'
import { useTemplate } from '../../useTemplate'
import { hasContrast, getContrast } from '../../../../helpers/color'

const getContrastingColor = ({ color, backgroundColor }) => (
  hasContrast(color, backgroundColor) ? color : getContrast(color)
)

const WatermarkSection = ({ mode, style, watermark }) => {
  const template = useTemplate()
  const section = (
    <tr>
      <td style={{
        textAlign: 'center',
        fontSize: '11px',
        padding: '20px 0',
        backgroundColor: style.backgroundColor,
        color: getContrastingColor(style),
      }}
      >Designed and sent for free with <a style={{ color: getContrastingColor({ ...style, color: style.linkColor }) }} href="https://enveloop.com">Enveloop</a>.
      </td>
    </tr>
  )
  if (template.slug === 'try') {
    return null
  }
  if (mode === 'render') {
    return (
      <>
        { '{{ #watermark }}' }
        { section }
        { '{{ /watermark }}' }
      </>
    )
  }

  return watermark !== false ? section : null
}

WatermarkSection.propTypes = {
  mode: PropTypes.string.isRequired,
  watermark: PropTypes.bool,
  style: PropTypes.object.isRequired,
}

WatermarkSection.defaultProps = {
  watermark: undefined,
}

export {
  WatermarkSection,
}
