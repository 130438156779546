import React from 'react'
import PropTypes from 'prop-types'

import { toColor } from '../../helpers/color'
import { spaceToSize } from '../../helpers/space'

const Swatch = ({
  color,
  space,
  style,
  size,
  width = size,
  height = size,
}) => {
  const s = {
    width: typeof width === 'number' ? `${width}px` : width,
    height: typeof height === 'number' ? `${height}px` : height,
    backgroundColor: toColor(color),
    borderRadius: '5px',
    padding: space ? spaceToSize(space) : null,
    ...style,
  }

  return (
    <div style={s} />
  )
}

Swatch.propTypes = {
  ...toColor.propTypes,
  ...spaceToSize.propTypes,
  style: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Swatch.defaultProps = {
  style: {},
  size: undefined,
  width: undefined,
  height: 10,
}

export {
  Swatch,
}
