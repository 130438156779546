import React from 'react'

// Creates a memoized broadcast channel, and sets an onMessage handler
const useChannel = (name, onmessage, deps = []) => {
  const onClose = React.useRef()
  const channel = React.useMemo(() => {
    const c = new BroadcastChannel(name)

    if (onmessage) c.onmessage = onmessage
    c.onClose = (callback) => { onClose.current = callback }

    return c
  }, [name, ...deps])

  // Cleanup to avoid memory leaks
  React.useEffect(() => () => {
    if (typeof onClose.current === 'function') onClose.current()
    channel.close()
  }, [name])

  return channel
}

export { useChannel }
