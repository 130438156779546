import React from 'react'

import { useTemplateConfig } from './Config'
import { useClipboard, useKeyUp } from '../../../hooks'
import { isActive } from './Element'
import { Button } from '../../Button'
import { Grid } from '../../Grid'
import { Text } from '../../Text'
import { Transition } from '../../Transition'

const useActiveType = () => {
  const { sectionId, elementId } = isActive()
  const { config } = useTemplateConfig()
  if (elementId) return { active: 'element', sectionId, elementId }
  if (sectionId) {
    const section = config.sections.find(({ variables: { id } }) => id === sectionId)
    if (!section) return {}
    return {
      active: (section?.sharedSectionId) ? 'sharedSection' : 'section',
      sectionId,
      elementId,
    }
  }
  return {}
}

const PasteBar = () => {
  // Get the active element or section so we know where to insert pastes
  const { active, elementId, sectionId } = useActiveType()
  const { addSection, addElement } = useTemplateConfig()
  const { inspect, paste, clear } = useClipboard()
  const { name: type, data } = inspect()
  const [pasteObj, setPasteObj] = React.useState()
  useKeyUp({ key: 'Escape', onPress: clear })

  const onClick = React.useCallback(() => {
    if (type === 'section') {
      // passes the ID of the selected section so it will insert the paste after it
      addSection({ section: paste().data, sectionId })
    }
    if (type === 'element') {
      // passes the ID of the selected element so it will insert the paste after it
      addElement({ element: paste().data, elementId, sectionId })
    }
  }, [type, data, elementId, sectionId])

  React.useEffect(() => {
    if (type === 'element') setPasteObj({ type, name: data.type })
    if (type === 'section') setPasteObj({ type, name: data.variables.config.name })
  }, [type, data?.type, data?.variables?.config?.name])

  const visible = ['section', 'element'].includes(type)
  const disabled = (
    type === 'element' && (!active || active === 'sharedSection')
  )

  const text = disabled ? 'Select a section to paste' : 'Copied'

  return (
    <Transition
      in={visible}
      unmountOnExit
      onExited={() => setPasteObj({})}
    >
      <div className="template-paste-bar-wrapper">
        <Grid className="template-paste-bar" valign="center" gap={5} templateColumns="min-content min-content min-content" data-type={type} inline>
          <Button
            className="template-paste-bar-clear"
            label="clear clipboard"
            icon={{ name: 'x-circle', color: 'neutral-300' }}
            onClick={clear}
          />
          <Text nowrap>{text} <strong>{pasteObj?.name}</strong> {pasteObj?.type}
          </Text>
          <Button
            className="template-paste-bar-paste"
            theme="menu"
            space={4}
            text="Paste"
            disabled={disabled}
            onClick={onClick}
          />
        </Grid>
      </div>
    </Transition>
  )
}

const TemplatePasteBar = () => <PasteBar />

export {
  TemplatePasteBar,
}
