import React from 'react'
import PropTypes from 'prop-types'
import {
  Main, P, Block, Stack, ButtonShelf, Button, SubmitButton, Notice, Grid,
  Text, Divider, SectionLabel, Card,
  toColor, renderMustache,
} from '@level'

import { useProfile, useSendPreview } from '@app/hooks'

import { TestDrawer } from '../test/drawer'
import { PreviewSMSTemplate } from './preview'
import { useAddPhoneDialog } from '../../account/addPhoneDialog'
import { useTemplateTest } from '../context'

import { SegmentedMessage } from 'sms-segments-calculator'

const useSMSTest = () => {
  const { Profile } = useProfile()
  const addPhone = useAddPhoneDialog()

  if (!Profile.phone) {
    return {
      button: <Button onClick={addPhone} text="Add Phone" />,
      message: (
        <Stack gap={5}>
          <P>
            Send an SMS with the test variables below to your account&rsquo;s
            phone number{Profile.phone ? <i> ({Profile.phone})</i> : null}.
          </P>
          <Notice space={4} icon={{ gap: 5, name: 'exclamation-triangle' }}>Please add a phone number to test SMS</Notice>
        </Stack>
      ),
    }
  }

  return {
    button: <SubmitButton text="Send SMS" disabled={!Profile.phone} />,
    message: (
      <P>
        Send an SMS with the test variables below to your account&rsquo;s
        phone number ({Profile.phone}).
      </P>
    ),
  }
}

const Estimate = ({ template }) => {
  const { previewVars } = useTemplateTest()
  const body = previewVars
    ? renderMustache(template.previewConfig.body, previewVars)
    : template.previewConfig.body

  const segmentedMessage = new SegmentedMessage(body)
  console.log({segmentedMessage})

  return (
    <>
      <SectionLabel>Estimated Message Size</SectionLabel>
      <Card space={6} style={{ backgroundColor: toColor('blue-25'), border: 'none' }}>
        <Grid gap={5} columns={2}>
          <Text>Encoding</Text> <Text tag="strong">{ segmentedMessage.encodingName }</Text>
          <Divider style={{ gridColumn: 'span 2' }} color="blue-100" />
          <Text>Total Characters</Text> <Text tag="strong">{ segmentedMessage.numberOfCharacters }</Text>
          <Divider style={{ gridColumn: 'span 2' }} color="blue-100" />
          <Text>Total Size</Text> <Text tag="strong">{ segmentedMessage.totalSize } bits</Text>
          <Divider style={{ gridColumn: 'span 2' }} color="blue-100" />
          <Text>Segments</Text> <Text tag="strong">{ segmentedMessage.segmentsCount }</Text>
        </Grid>
      </Card>
    </>
  )
}

const SMSDrawer = ({ template, ...props }) => {
  const { message, button } = useSMSTest()

  return (
    <TestDrawer {...props}>
      <Block space={6}>
        <Stack gap={6}>
          <Estimate template={template} />
          { message }
          <ButtonShelf>
            { button }
          </ButtonShelf>
        </Stack>
      </Block>
    </TestDrawer>
  )
}

const TestSMSTemplate = ({ template }) => {
  const { onSubmit } = useSendPreview({ template })

  return (
    <Main drawer={(
      <SMSDrawer
        template={template}
        title="Test SMS Template"
        templateVariables={template.previewTemplateVariables}
        onSubmit={onSubmit}
      />
    )}
    >
      <PreviewSMSTemplate {...template} mode="render" />
    </Main>
  )
}
TestSMSTemplate.propTypes = {
  template: PropTypes.object.isRequired,
}

export {
  TestSMSTemplate,
}
