import React from 'react'
import {
  useWatch,
  useFormContext,
  FormProvider as HookFormProvider,
  useForm as reactHookForm,
} from 'react-hook-form'
import { useLeadingDebounce } from './useDebounce'

const useFormRef = () => useFormContext() || {}

const focusInput = ({ form, focus }) => {
  if (!focus) return null
  if (focus && focus.match(/^(?:#|\.|\[)/)) return form.querySelector(focus)

  const inputQuery = 'input:not([type=hidden]), select, textarea, button'

  if (focus === 'first') return form.querySelector(inputQuery)
  if (focus === 'firstEmpty') {
    const inputs = form.querySelectorAll(inputQuery)
    return Array.prototype.find.call(inputs, (i) => !i.value) || inputs[0]
  }

  return focus ? form.querySelector(focus.match(/\[name=/) ? focus : `[name="${focus}"]`) : null
}

const useDebounceSubmit = ({ onSubmit, debounceSubmit }) => {
  const delay = typeof debounceSubmit === 'number' ? debounceSubmit : 500
  const debounced = useLeadingDebounce(onSubmit, [], delay)
  if (!debounceSubmit) return onSubmit
  return debounced
}

// Wraps the useForm hook with helpful error checking and default merging
const useForm = ({
  onSubmit,
  defaultValues = {},
  shouldUseNativeValidation = true,
  reset,
  resetWith = {},
  watch,
  onChange,
  onChangeInput,
  onError,
  debounceSubmit,
  ...rest
}) => {
  const formRef = reactHookForm({
    shouldUseNativeValidation,
    defaultValues,
    ...rest,
  })

  const submit = useDebounceSubmit({ onSubmit, debounceSubmit })
  const ref = React.useRef()
  const validations = React.useRef({})
  const resetKey = React.useRef(null)
  const resetRef = React.useRef(null)
  const changed = React.useRef(false)

  // Set which fields fire an onChange event. Default: any field will trigger if onChange is present
  const watchedFields = onChange ? useWatch({ name: watch, control: formRef.control }) : null

  // Fire the onChange if:
  // - the callback onChange exists
  // - the form has changes from the default state || it has been changed back to the default state
  React.useEffect(() => {
    if (onChange && (formRef.formState.isDirty || changed.current)) {
      changed.current = true
      onChange(watchedFields)
    }
  }, [watchedFields])

  // Merge from data over default values. This ensures that default values are
  // present even if they weren't available as a hidden input on the form.
  formRef.submit = formRef.handleSubmit(async (data) => {
    // Run preflight validations
    const checks = Object.values(validations.current)
    const result = await Promise.all(checks.map((check) => check({ data, formRef })))

    // If validations pass fire away
    if (!result.includes(false)) {
      // Merge data with default values allowing you to have inputs which are a subset of your data
      submit({ data: { ...defaultValues, ...data }, formRef })
    }
  })

  React.useEffect(() => {
    // Do not allow reset to fire on load
    if (resetKey.current) {
      formRef.reset({ ...defaultValues, ...resetWith })
      resetRef.current = reset
    }

    resetKey.current = reset
    // Reset the change tracker
    changed.current = false
  }, [reset])

  React.useEffect(() => {
    if (Object.keys(formRef.formState.errors).length && onError) onError(formRef.formState.errors)
  }, [formRef.formState.errors])

  formRef.resetRef = resetRef
  formRef.formElRef = ref

  formRef.watch = (props) => useWatch({ control: formRef.control, ...props })

  formRef.focus = (focus, delay = 0) => {
    const input = focusInput({ form: ref.current, focus })
    if (input) setTimeout(() => input.focus(), delay)
  }

  // callback to allow you to add preflight validations to form data
  formRef.addValidation = (name, fn) => {
    validations.current = { ...validations.current, [name]: fn }
  }

  formRef.defaultValues = defaultValues
  formRef.onChangeInput = onChangeInput

  return formRef
}

const FormProvider = ({ children, ...props }) => (
  <HookFormProvider {...useForm(props)}>
    { children }
  </HookFormProvider>
)

export {
  useForm,
  useFormRef,
  FormProvider,
}
