import React from 'react'
import PropTypes from 'prop-types'
import {
  Main, P, Block, Stack, ButtonShelf, Button, SubmitButton, Notice,
} from '@level'

import { useProfile, useSendPreview } from '@app/hooks'

import { TestDrawer } from '../test/drawer'
import { PreviewSMSTemplate } from './preview'
import { useAddPhoneDialog } from '../../account/addPhoneDialog'

const useSMSTest = () => {
  const { Profile } = useProfile()
  const addPhone = useAddPhoneDialog()

  if (!Profile.phone) {
    return {
      button: <Button onClick={addPhone} text="Add Phone" />,
      message: (
        <Stack gap={5}>
          <P>
            Send an SMS with the test variables below to your account&rsquo;s
            phone number{Profile.phone ? <i> ({Profile.phone})</i> : null}.
          </P>
          <Notice space={4} icon={{ gap: 5, name: 'exclamation-triangle' }}>Please add a phone number to test SMS</Notice>
        </Stack>
      ),
    }
  }

  return {
    button: <SubmitButton text="Send SMS" disabled={!Profile.phone} />,
    message: (
      <P>
        Send an SMS with the test variables below to your account&rsquo;s
        phone number ({Profile.phone}).
      </P>
    ),
  }
}

const SMSDrawer = (props) => {
  const { message, button } = useSMSTest()

  return (
    <TestDrawer {...props}>
      <Block space={6}>
        <Stack gap={6}>
          { message }
          <ButtonShelf>
            { button }
          </ButtonShelf>
        </Stack>
      </Block>
    </TestDrawer>
  )
}

const TestSMSTemplate = ({ template }) => {
  const { onSubmit } = useSendPreview({ template })

  return (
    <Main drawer={(
      <SMSDrawer
        title="Test SMS Template"
        templateVariables={template.previewTemplateVariables}
        onSubmit={onSubmit}
      />
    )}
    >
      <PreviewSMSTemplate {...template} mode="render" />
    </Main>
  )
}
TestSMSTemplate.propTypes = {
  template: PropTypes.object.isRequired,
}

export {
  TestSMSTemplate,
}
