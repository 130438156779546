import React from 'react'
import PropTypes from 'prop-types'

import { useTemplate } from '../../useTemplate'
import { oldFontSizes, fontFamilies } from '../Config/defaults'
import {
  getContrast, transformColor,
  inherit, filterEmptyValues,
} from '../../../../helpers'
import { Link } from '../../../Link'
import { Shelf } from '../../../Shelf'
import { Icon } from '../../../Icon'
import { useSearchState } from '../../../../hooks/useSearchState'
import { MenuButton } from '../../../MenuButton'
import { useSortable } from '../../../Draggable'

// Look up to the template { state: path } to see if a
// section/element is selected in the current path
const isActive = (props = {}) => {
  const { sectionId, elementId } = props
  const { state: { path } } = useTemplate()

  if (elementId) return path?.elementId === elementId || null
  if (sectionId && path?.sectionId === sectionId) return true
  if (!sectionId && !elementId) return path

  return null
}

const toFontSize = (size) => oldFontSizes[size] || size

const getConfigs = ({ element: { config }, sectionConfig, templateVariables }) => (
  [config, config?.tableConfig, sectionConfig, templateVariables?.style].filter((v) => !!v)
)

const getColor = (props) => {
  const configs = getConfigs(props)
  const color = inherit({ key: 'color', configs })
  const backgroundColor = inherit({ key: 'backgroundColor', configs })

  if (color) return color
  if (backgroundColor) return getContrast(backgroundColor)
  return null
}

const getLinkColor = (props) => inherit({ key: 'linkColor', configs: getConfigs(props) })

const getFontFamily = (props) => (
  fontFamilies[inherit({ key: 'fontFamily', configs: getConfigs(props), exclude: 'inherit' })] || 'inherit'
)

const placeholderColor = (initial, props) => {
  const { element: { config }, mode } = props

  const placeholder = mode.match('builder') && !(config.content || config.text)

  const color = !initial ? '#000000' : initial
  return placeholder
    ? transformColor(color).set({ alpha: 0.3 }).toString()
    : initial
}

const elementStyle = (props, defaultConfig = {}) => {
  const {
    element: {
      type,
      config,
    },
  } = props

  const {
    fontWeight,
    fontSize,
    lineHeight,
    backgroundColor,
    padding,
    minWidth,
    buttonPadding,
    cornerStyle,
    color,
  } = { ...defaultConfig, ...config, ...(config?.tableConfig || {}) }

  const style = {
    fontSize,
    fontFamily: getFontFamily(props),
    backgroundColor,
    padding,
    cornerStyle,
    minWidth,
    fontWeight,
    msoLineHeight: 'exact',
    lineHeight,
    color,
  }

  if (type === 'Button') {
    style.backgroundColor = style.backgroundColor || getLinkColor(props)
    style.color = style.color || getContrast(style.backgroundColor)
    style.buttonPadding = buttonPadding
  } else {
    style.color = getColor(props)
  }

  return filterEmptyValues(style)
}

const ElementTd = ({
  children, active, ...props
}) => {
  const { element: { id }, style = {}, dragging } = props
  const align = inherit({ key: 'align', configs: getConfigs(props) })
  const linkColor = getLinkColor(props)
  return (
    <td
      id={id}
      className="enveloop-element"
      data-type={props.element.type}
      align={align}
      style={style}
      data-active={active}
      data-dragging={dragging}
      data-drag-clone={props.mode.match(/clone-element/) || undefined}
    >
      { /* If element can have links as children add a style to set linkColor */ }
      { linkColor && ['Text', 'Heading'].includes(props.element.type) ? <style>{`#${id} a { color: ${linkColor} }`}</style> : null }
      { children }
    </td>
  )
}

ElementTd.propTypes = {
  element: PropTypes.shape({
    config: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  sectionConfig: PropTypes.object,
  columnConfig: PropTypes.object,
  children: PropTypes.node,
}

ElementTd.defaultProps = {
  sectionConfig: undefined,
  columnConfig: undefined,
  children: undefined,
}

const ElementTag = ({
  editing, element, sectionId, active, mode
}) => {
  const name = React.useMemo(() => {
    const base = `${element.type}`
    const editable = ['Button', 'Text', 'Heading'].includes(element.type)

    if (editing) return `${base} (editing)`
    if (editable) return `${base} (click to edit)`
    return base
  }, [editing])

  const { hooks } = useTemplate()
  const { listeners, setActivatorNodeRef } = useSortable({ id: element.id })

  const menuActions = React.useMemo(() => {
    const actions = []

    if (hooks.addElement) {
      actions.push({
        text: 'Duplicate',
        onSelect: () => hooks.addElement({ element, sectionId }),
        icon: { name: 'copy' },
      })
    }
    if (hooks.copyElement && mode === 'builder') {
      actions.push({
        text: 'Copy',
        onSelect: () => hooks.copyElement(element),
        icon: { name: 'arrow-up-from-bracket' },
      })
    }
    if (hooks.confirmDeleteElement) {
      actions.push({
        text: 'Delete',
        destructive: true,
        divider: true,
        onSelect: () => hooks.confirmDeleteElement({ element, sectionId }),
        icon: { name: 'trash' },
      })
    }
    return actions
  }, [hooks.copyElement, hooks.addElement, hooks.confirmDeleteElement])

  return (
    <div className="template-link-tag">
      <Shelf valign="center" style={{ height: '100%' }}>
        <Shelf
          className="level-template-drag-button"
          gap={4}
          valign="center"
          ref={setActivatorNodeRef}
          {...listeners}
        >
          <Icon name="arrows-up-down-left-right" />
          { active ? name : null }
        </Shelf>
        { active ? <MenuButton label="Element actions" items={menuActions} size={2} icon={{ color: 'white', name: 'three-dots-horizontal' }} theme="wrapper" /> : null }
      </Shelf>
    </div>
  )
}

const ElementLinkTd = ({ children, ...props }) => {
  const { element, sectionId } = props
  const [params] = useSearchState()
  const editing = !!params.editing
  const { isDragging } = useSortable({ id: element.id })
  const active = isActive({ elementId: element.id })
  const dragging = props.dragging || isDragging ? 'element' : undefined
  return (
    <ElementTd {...props} active={active} dragging={dragging}>
      { children }
      <Link
        to={`element/${props.element.id}`}
        label={`Edit ${element.type} element`}
        title=""
        data-active={active}
        data-editing={active && editing ? true : null}
        data-type="element"
        className="enveloop-element-link"
      />
      { active !== 'section' ? (
        <ElementTag
          editing={editing}
          element={element}
          sectionId={sectionId}
          active={active}
          mode={props.mode}
        />
      ) : null }
    </ElementTd>
  )
}

ElementLinkTd.propTypes = {
  ...ElementTd.propTypes,
  sectionId: PropTypes.string.isRequired,
}

const ElementWrapper = (props) => {
  const { mode, sharedSectionId } = props

  if (mode.match('builder') && !sharedSectionId) {
    return (
      <ElementLinkTd {...props} />
    )
  }

  return <ElementTd {...props} />
}

ElementWrapper.propTypes = ElementTd.propTypes
ElementWrapper.defaultProps = ElementTd.defaultProps

export {
  inherit,
  getColor,
  toFontSize,
  getLinkColor,
  placeholderColor,
  ElementWrapper,
  elementStyle,
  isActive,
}
