import React from 'react'
import PropTypes from 'prop-types'

import { HiddenInput } from '../../../../Input'
import { Stack } from '../../../../Stack'
import { Shelf } from '../../../../Shelf'
import { Column } from '../../../../Column'
import { Button } from '../../../../Button'
import { SliderInput } from '../../../../SliderInput'
import { useFormRef } from '../../../../../hooks/useForm'
import { camelToTitle } from '../../../../../helpers/text'
import { toPadding, toPaddingValue } from '../../../../../helpers/react'

const isLocked = ({
  top, bottom, left, right,
}) => (
  top === bottom && left === right
)

const vertical = ['top', 'bottom']
const horizontal = ['left', 'right']

const PaddingInput = ({
  name, defaultValue, onChange: onChangeProp,
}) => {
  const { setValue, onChangeInput } = useFormRef()
  const pad = React.useRef(toPadding(defaultValue))

  const lock = React.useRef(isLocked(pad.current))
  const [locked, setLocked] = React.useState(isLocked(pad.current))
  const inputs = locked ? ['vertical', 'horizontal'] : ['top', 'right', 'bottom', 'left']

  // Returns a handler which can update one or more properites (keys) of the padding
  const onChange = (direction) => ({ target: { value: inputValue } }) => {
    let keys = [direction]

    if (lock.current && vertical.includes(direction)) keys = vertical
    if (lock.current && horizontal.includes(direction)) keys = horizontal

    const val = Number.parseInt(inputValue, 10)
    // Convert [top, bottom] => { top: 10, bottom: 10 }
    pad.current = keys.reduce((acc, key) => ({ ...acc, [key]: val }), pad.current)
    const padValue = toPaddingValue(pad.current)

    // Update hidden form input
    setValue(name, padValue, { shouldDirty: true })

    if (onChangeInput) onChangeInput({ [name]: padValue })
    if (onChangeProp) onChangeProp({ [name]: padValue })

    // Update linked value
    if (keys.length !== 1) {
      keys.forEach((key) => {
        if (key !== direction) {
          setValue(`${name}_pad.${key}`, inputValue, { shouldDirty: false, shouldTouch: false })
        }
      })
    }
  }

  const toggleLock = () => {
    // Sync values when lock is triggered
    pad.current.bottom = pad.current.top
    pad.current.left = pad.current.right

    // Update hidden input value with expanded padding (e.g. 10px 15px 10px 15px)
    setValue(name, toPaddingValue(pad.current), { shouldDirty: true })
    if (onChangeInput) onChangeInput({ [name]: toPaddingValue(pad.current) })

    // Before unlocking inputs ensure all values are consistent
    if (locked) {
      setValue(`${name}_pad.bottom`, pad.current.bottom, { shouldDirty: true })
      setValue(`${name}_pad.left`, pad.current.left, { shouldDirty: true })
    }

    // Toggle lock
    lock.current = !lock.current
    setLocked((l) => !l)
  }

  return (
    <>
      <HiddenInput name={name} defaultValue={defaultValue} />
      <Shelf gap={5} valign="center">
        <Column grow>
          <Stack gap={3}>
            { inputs.map((direction) => {
              const dir = { vertical: 'top', horizontal: 'right' }[direction] || direction
              const label = camelToTitle(`${direction} ${name}`)
              return (
                <SliderInput
                  key={dir}
                  step={5}
                  type="range"
                  label={label}
                  name={`${name}_pad.${dir}`}
                  defaultValue={pad.current[dir] || 0}
                  unit="px"
                  onChange={onChange(dir)}
                />
              )
            })}
          </Stack>
        </Column>
        <Button
          theme="menu"
          icon={{
            name: locked ? 'lock' : 'lock-open',
            color: locked ? 'blue-primary' : null,
          }}
          onClick={toggleLock}
          label={locked ? 'Separate padding' : 'Sync padding'}
        />
      </Shelf>
    </>
  )
}

PaddingInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
}

PaddingInput.defaultProps = {
  defaultValue: '',
  onChange: undefined,
}

export {
  PaddingInput,
}
