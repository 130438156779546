import React from 'react'
import PropTypes from 'prop-types'
import { EmailTable, toPadding } from '../../../../helpers'
import { SectionLink } from './SectionLink'
import Elements from '../Element'
import {
  Droppable, SortableItem, SortableContext,
} from '../../../Draggable'

const SortableColumnElement = ({ id, mode, children }) => {
  if (mode.match(/builder/)) {
    return (
      <SortableItem as="tr" id={id} handle>
        { children }
      </SortableItem>
    )
  }
  return <tr>{ children }</tr>
}

const SectionColumnElements = ({
  mode,
  index,
  config,
  elements,
  section,
  columnIndex,
  sharedSectionId,
  templateVariables,
}) => (
  elements?.map(({ Element, element }, elIndex) => (
    <React.Fragment key={element.id}>
      <SortableColumnElement mode={mode} id={element.id}>
        <Element
          mode={mode}
          element={element}
          varPath={`columns.${index}.elements.${elIndex}.config`}
          columnConfig={config}
          columnIndex={columnIndex}
          elementIndex={elIndex}
          sectionConfig={section.config}
          templateVariables={templateVariables}
          // Shared sections are at the root, otherwise they are nested
          // Note: I wish this logic didn't have to live in the template
          sectionId={section.id}
          sharedSectionId={sharedSectionId}
        />
      </SortableColumnElement>
      { elIndex !== elements.length - 1 && section.config.elementSpacing ? (
        <tr><td style={{ height: section.config.elementSpacing }} /></tr>
      ) : null }
    </React.Fragment>
  ))
)

const SectionDropTarget = ({
  id, index, section, elements, sharedSectionId,
}) => {
  // Get negative position to account for section padding
  const sectionPadding = toPadding(section.config.padding)
  const pad = Object.entries(sectionPadding).reduce((ag, [pos, val]) => (
    { ...ag, [pos]: `${10 - val}px` }
  ), {})

  if (section.columns.length > 1) {
    let { columnSpace } = section.config
    if (!columnSpace) columnSpace = sectionPadding.right
    if (index === 0) pad.right = `${5 - (columnSpace / 2)}px`
    if (index === 1) pad.left = `${5 - (columnSpace / 2)}px`
  }

  const droppableProps = {
    id,
    overChildren: elements?.map(({ element }) => element.id),
    disabled: !!sharedSectionId,
    style: {
      position: 'absolute',
      ...pad,
    },
  }
  return <Droppable className="level-column-drop-target" {...droppableProps} />
}

// Section Column also gets a `config` for right now we're not using it
// Config will hold some kind of column spacing and alignment system.
const SectionColumn = (props) => {
  const {
    config,
    section,
    elements,
    id,
    mode,
  } = props

  return (
    <td
      mode={mode}
      className="enveloop-section-column"
      width={config.width || `${100.0 / section.columns.length}%`}
      valign={section.config.valign || 'top'}
    >
      { mode.match(/builder/) ? <SectionDropTarget elements={elements} id={id} {...props} /> : undefined }
      { elements?.length ? (
        <EmailTable width="100%">
          <tbody>
            <SectionColumnElements
              config={config}
              section={section}
              elements={elements}
              {...props}
            />
          </tbody>
        </EmailTable>
      ) : null }
      { mode.match(/builder/) ? <SectionLink id={section.id} /> : undefined }
    </td>
  )
}

SectionColumn.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    config: PropTypes.object,
  }).isRequired).isRequired,
  index: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  sharedSectionId: PropTypes.string,
  mode: PropTypes.string,
  templateVariables: PropTypes.object,
}

SectionColumn.defaultProps = {
  mode: undefined,
  sharedSectionId: undefined,
  templateVariables: {},
}

const Column = ({ elements, ...props }) => {
  const items = elements?.map(({ element }) => element.id)
  return (props.sharedSectionId || !props.mode.match(/builder/))
    ? <SectionColumn elements={elements} {...props} />
    : (
      <SortableContext id={props.id} items={items}>
        <SectionColumn elements={elements} {...props} />
      </SortableContext>
    )
}

Column.propTypes = SectionColumn.propTypes
Column.defaultProps = SectionColumn.defaultProps

const SectionColumns = ({ columns, ...rest }) => {
  const { columnSpace, ...sectionConfig } = rest.section.config
  const columnWidth = columnSpace || Number.parseInt(sectionConfig.padding.split(' ')[1], 10)
  const columnHeight = Number.parseInt(sectionConfig.padding.split(' ')[0], 10)

  const cols = columns.map(({ id, elements, config }, index) => (
    <React.Fragment key={id}>
      { index !== 0 ? (
        <td className="enveloop-column-space" width={`${columnWidth}px`} height={`${columnHeight}px`}>
          { rest.mode.match(/builder/) ? (
            <SectionLink id={rest.section.id}>
              <div style={{ width: `${columnWidth}px`, height: `${columnHeight}px` }} />
            </SectionLink>
          ) : <div style={{ width: `${columnWidth}px`, height: `${columnHeight}px` }} /> }
        </td>
      ) : undefined }
      <Column
        id={id}
        columnIndex={index}
        config={config}
        elements={elements.filter((e) => e?.id)
          .map((e) => ({ element: e, Element: Elements[e.type] }) || [])}
        index={index}
        {...rest}
      />
    </React.Fragment>
  ))
  return <tr>{ cols }</tr>
}

SectionColumns.propTypes = {
  columns: PropTypes.array.isRequired,
}

export {
  SectionColumn,
  SectionColumns,
}
