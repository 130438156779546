import { templateConfigDefaults } from '@level'

const themeColors = {
  templateBg: '#FFFFFF',
  text: '#303132',
  accentBg: '#DEDFE2',
  linkColor: '#0000FF',
}

const theme = (config) => {
  const colors = {
    ...themeColors,
  }

  return ({
    colors,
    variables: {
      style: {
        ...templateConfigDefaults.template.style,
        color: colors.text,
        backgroundColor: colors.templateBg,
        linkColor: colors.linkColor,
        fontFamily: 'sans-serif',
        padding: '20px 20px 20px 20px',
        width: '100%',
      },
      config: {
        ...templateConfigDefaults.template.config,
        ...config,
      },
      defaults: {
        section: {
          padding: '0',
        },
        Heading: {
          color: colors.text,
        },
        Divider: {
          color: colors.accentBg,
          padding: '10px 0 10px 0',
        },
        Button: {
          cornerStyle: 'rounded',
          padding: '25px 0px 25px 0px',
          fontWeight: 600,
          fontSize: 1,
        },
      },
    },
  })
}

const colors = Object.keys(themeColors)

export {
  theme,
  colors,
}
