import React from 'react'
import {
  ButtonShelf, MenuButton, Button,
  useLiveDate, timeAgoOrDate,
  useNetworkStatus,
} from '@level'

import { useSharedSection } from './provider'

const SharedSectionMenuActions = () => {
  const { online } = useNetworkStatus()

  const {
    sharedSection: { publishedAt, published },
    deleteSection,
    revertSection,
    publishSection,
  } = useSharedSection()

  const date = useLiveDate({ date: publishedAt, format: timeAgoOrDate })

  const menuActions = React.useMemo(() => {
    const actions = []

    if (publishedAt && !published) {
      actions.push({
        text: `Revert (${date})`,
        onSelect: revertSection,
        icon: 'arrow-rotate-left',
      })
    }
    actions.push({
      text: 'Delete',
      onSelect: deleteSection,
      icon: 'trash',
      destructive: true,
      divider: !!actions.length,
    })

    return actions
  }, [
    publishedAt,
    published,
    deleteSection,
    revertSection,
  ])

  return (
    <ButtonShelf>
      <Button text="Publish" theme={published ? null : 'primary-green'} onClick={publishSection} disabled={published || !online} />
      <MenuButton items={menuActions} size={4} icon="gear-solid" label="Template options" />
    </ButtonShelf>
  )
}

export {
  SharedSectionMenuActions,
}
