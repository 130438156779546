import React from 'react'
import PropTypes from 'prop-types'

import { autoEscapeMustache } from '../../../../../helpers/mustache'
import { Input } from '../../../../Input'
import { Stack } from '../../../../Stack'
import { ImageDropInput } from '../../../../ImageDropInput'
import { useFormRef } from '../../../../../hooks/useForm'
import { useTemplateConfig } from '../useTemplateConfig'

const ImageInput = ({ name, defaultValue }) => {
  const { setValue, watch, onChangeInput } = useFormRef()
  const src = watch({ name })
  const { uploadImage } = useTemplateConfig()

  React.useEffect(() => {
    if (defaultValue) setValue(name, defaultValue, { shouldDirty: true })
  }, [defaultValue])

  const onDrop = uploadImage ? async (image) => {
    if (image) {
      const { url } = await uploadImage(image)
      if (url) {
        setValue(name, url, { shouldDirty: true })
        onChangeInput({ [name]: url })
      }
    } else {
      setValue(name, '')
      onChangeInput({ [name]: null })
    }
  } : null

  return (
    <Stack gap={5}>
      <Input label="Image Source URL" name={name} defaultValue={defaultValue} setValueAs={autoEscapeMustache} />
      { onDrop ? <ImageDropInput name={`${name}-drop`} onChange={onDrop} src={src} /> : null }
    </Stack>
  )
}

ImageInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
}

ImageInput.defaultProps = {
  defaultValue: undefined,
}

export { ImageInput }
