import React from 'react'
import PropTypes from 'prop-types'

import {
  Block, Shelf, Text, Spacer, Button, Stack, Editor,
  useDialog, formatDateTime,
} from '@level'

import { ErrorNameCode } from './helpers'

const ErrorHeader = ({
  close,
  selected: error,
  selectNext,
  selectPrevious,
}) => (
  <>
    <Shelf gap={4} space={[7, 7, 0]} valign="center">
      <Text tag="h3"><ErrorNameCode error={error.error} /></Text>
      <Spacer grow />
      <Button theme="wrapper" label="close" icon={{ name: 'x-light', size: 4 }} onClick={close} />
    </Shelf>
    <Shelf space={7} className="message-log-item-meta" align="split" valign="top">
      <Shelf gap={6}>
        <Stack gap={2}>
          <Text><strong>path:</strong> {error.path}</Text>
          <Text><strong>date:</strong> {formatDateTime(error.createdAt, { utc: true })}</Text>
          { error.sdkVersion ? (
            <Text><strong>SDK Version:</strong> {error.sdkVersion}</Text>
          ) : null }
        </Stack>
      </Shelf>
      <Shelf>
        { error.templateLink ? <Button icon="pen" to={error.templateLink} size={4} text="Edit Template" /> : null}
        <Spacer space={4} />
        <Button onClick={selectPrevious} icon="angle-left" label="previous message" disabled={!selectPrevious} />
        <Button onClick={selectNext} icon="angle-right" label="next message" disabled={!selectNext} />
      </Shelf>
    </Shelf>
  </>
)

const ErrorModal = ({
  dialogContainerRef,
  ...rest
}) => (
  <>
    <ErrorHeader {...rest} />
    <Block space={7} style={{ backgroundColor: '#F5F6F7' }} maxHeight rootRef={dialogContainerRef}>
      <Stack gap={5}>
        <Text tag="h4">Message Body</Text>
        <Editor content={JSON.stringify(rest.selected.body, null, 2)} lang="json" />
      </Stack>
    </Block>
  </>
)

ErrorModal.propTypes = {
  // Shallow messsage from table data
  selected: PropTypes.object.isRequired,
  dialogContainerRef: PropTypes.object.isRequired,
}

const useErrorModal = ({ table }) => {
  const {
    selected, selectPrevious, selectNext, deselectAll,
  } = table.getSelection(table)
  const sendDialog = useDialog()

  // View Message when selected
  React.useEffect(() => {
    if (selected) {
      sendDialog({
        element: ErrorModal,
        selected,
        selectPrevious,
        selectNext,
        onClose: deselectAll,
        cancelText: 'close',
        width: '80vw',
        height: '80vh',
      })
    }
  }, [selected])
}

export {
  useErrorModal,
}
