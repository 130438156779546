import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '../Stack'
import { Text } from '../Text'

const ErrorView = ({ error, info }) => {
  // return detailed info and debug information in development environment
  if (process.env.NODE_ENV === 'development') {
    return (
      <Stack gap={5}>
        <Text tag="h1">{error.message}</Text>
        <Text tag="pre" style={{ whiteSpace: 'pre-wrap' }}>{error.stack}</Text>
        <Text tag="h3">Component Stack</Text>
        <Text tag="pre">{info.componentStack}</Text>
      </Stack>
    )
  }

  // return a generic error page in non-development environments
  return (
    <Stack>
      <Text tag="h1">Something went wrong</Text>
    </Stack>
  )
}

ErrorView.propTypes = {
  error: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
}

export { ErrorView }
