import { confirmAccount } from './confirmAccount'
import { forgotPassword } from './forgotPassword'
import { magicLink } from './magicLink'
import { orderConfirmation } from './orderConfirmation'
import { userInvite } from './userInvite'
import { userWelcome } from './userWelcome'
import { colors } from './theme'

const templates = [
  confirmAccount,
  forgotPassword,
  magicLink,
  userInvite,
  userWelcome,
  orderConfirmation,
]

export default {
  templates,
  colors,
}
