import React from 'react'
import {
  TextFilter, FilterPill, HeaderFilter,
  DateRangeFilter, DateFilterPill as DatePill,
} from '@level'

const ErrorFilter = () => (
  <TextFilter name="error" placeholder="Error text" />
)

const ErrorFilterPill = ({ params }) => (
  <FilterPill name="error" content={<ErrorFilter />} display={`"${params.error}"`} />
)

const ErrorFilterHeader = () => <HeaderFilter name="error" content={<ErrorFilter />} />

const SdkVersionFilter = () => (
  <TextFilter name="sdkVersion" placeholder="SDK Version text" />
)
const SdkVersionFilterPill = ({ params }) => (
  <FilterPill name="sdkVersion" param="SDK Version" content={<SdkVersionFilter />} display={`"${params.sdkVersion}"`} />
)

const SdkVersionFilterHeader = () => <HeaderFilter name="sdk Version" content={<SdkVersionFilter />} />

const DateFilter = () => (
  <DateRangeFilter
    defaults={{ date: '' }}
    span={[
      'all',
      'lastHour',
      'today',
      'yesterday',
    ]}
    dateRange={[
      '10 minutes',
      '30 minutes',
      '1 hour',
      '2 hours',
      '3 hours',
      '6 hours',
      '12 hours',
      '1 day',
      '2 days',
      '5 days',
    ]}
  />
)

const DateFilterHeader = () => <HeaderFilter name="date" activeParams={['createdAt']} content={<DateFilter />} />
const DateFilterPill = () => <DatePill name="date" content={<DateFilter />} clear={['date', 'createdAt']} />

export {
  DateFilterPill,
  DateFilterHeader,
  ErrorFilterPill,
  ErrorFilterHeader,
  SdkVersionFilterPill,
  SdkVersionFilterHeader,
}
