import { theme } from './theme'

const userInvite = {
  name: 'User Invite',
  config: ({ color, Team = { name: 'CompanyName' }, Project = { name: 'ProductName' } }) => {
    const {
      colors: {
        heading,
        textSubtle,
        templateBg,
        accentBg,
        textFooter,
      },
      variables,
    } = theme({ color, defaultSubject: 'Hi {{{ name }}}, You\'ve been invited' })

    return {
      variables,
      sections: [
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '30px 40px 30px 40px',
              elementSpacing: '15px',
              name: 'Header',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Text',
                    config: {
                      content: Team.name,
                      color: heading,
                      padding: '0 0 0 0',
                      fontSize: '16px',
                      fontWeight: '600',
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          sharedSectionId: null,
          variables: {
            config: {
              name: 'Content',
              align: 'left',
              valign: 'top',
              padding: '25px 40px 25px 40px',
              elementSpacing: '20px',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Heading',
                    config: {
                      padding: '0 0 0 0',
                      fontSize: '30px',
                      fontWeight: 600,
                      text: `{{sender}} would like to collaborate on ${Project.name}`,
                      color: heading,
                      align: 'left',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      padding: '0 0 0 0',
                      fontSize: '14px',
                      fontWeight: 400,
                      content:
                        `{{sender}} with ${Team.name} has invited you to join ${Project.name}. Click the button below to setup your account and start collaborating.`,
                    },
                  },
                  {
                    type: 'Button',
                    config: {
                      minWidth: '0%',
                      buttonPadding: '10px 25px 10px 25px',
                      padding: '15px 0px 15px 0px',
                      fontSize: '12px',
                      fontWeight: 600,
                      cornerStyle: 'rounded',
                      text: 'Create my account',
                      url: '{{{account_url}}}',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content:
                        'If you think this was sent in error, reply back to this email and let the sender, {{sender}}, know.',
                      padding: '15px 0px 15px 0px',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: textSubtle,
                    },
                  },
                  {
                    type: 'Divider',
                    config: {
                      color: accentBg,
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      padding: '15px 0px 15px 0px',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content:
                        'If you have trouble with the button above, copy and paste the URL below into your web browser.\n\n{{account_url}}',
                      color: textFooter,
                      align: 'center',
                      padding: '15px 0px 15px 0px',
                      fontSize: '10px',
                      fontWeight: 400,
                    },
                  },
                ],
              },
            ],
          },
        },
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '15px 0px 15px 0px',
              elementSpacing: '15px',
              backgroundColor: templateBg,
              name: 'Footer',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Text',
                    config: {
                      content:
                        `© Copyright ${new Date().getFullYear()} ${Team.name}. All Rights Reserved.`,
                      color: textFooter,
                      padding: '25px 0px 25px 0px',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    }
  },
}

export { userInvite }
