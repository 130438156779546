import { theme } from './theme'

const orderConfirmation = {
  name: 'Order Confirmation',
  config: ({ color }) => {
    const {
      colors: {
        text,
        accentBg,
      },
      variables,
    } = theme({ color, defaultSubject: 'Your order is confirmed' })

    return {
      variables,
      sections: [
        {
          sharedSectionId: null,
          variables: {
            config: {
              align: 'left',
              valign: 'top',
              padding: '0px 0px 0px 0px',
              elementSpacing: '10px',
              name: 'Content',
            },
            columns: [
              {
                config: {},
                elements: [
                  {
                    type: 'Heading',
                    config: {
                      text: 'Your order is confirmed',
                      color: text,
                      padding: '0 0 0 0',
                      fontSize: '30px',
                      fontWeight: '600',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: 'This email is your receipt for your purchase. No payment is due. [Download Receipt]({{{receipt}}})',
                      padding: '0 0 0 0',
                      fontSize: '14px',
                      fontWeight: '400',
                    },
                  },
                  {
                    type: 'Table',
                    config: {
                      name: 'table',
                      tableConfig: {
                        columns: [
                          {
                            id: 'column1',
                            align: 'left',
                          },
                          {
                            id: 'column2',
                            align: 'left',
                          },
                        ],
                        body: {
                          rows: [
                            {
                              id: 'row1',
                              type: 'text',
                              columns: [
                                '{{invoice_date}}',
                                '{{billing_name}}',
                              ],
                            },
                            {
                              id: 'row-00127380-dcdd-4801-985e-4193b4687135',
                              columns: [
                                '{{invoice_number}}',
                                '{{billing_address}}',
                              ],
                              type: 'text',
                            },
                          ],
                          borders: {
                            horizontal: false,
                            vertical: false,
                            outerHorizontal: false,
                            outerVertical: false,
                          },
                          style: {
                            color: null,
                            borderColor: null,
                            backgroundColor: null,
                          },
                        },
                        header: {
                          rows: [],
                          borders: {
                            horizontal: false,
                            vertical: false,
                            outerHorizontal: false,
                            outerVertical: false,
                            match: true,
                          },
                          style: {
                            color: null,
                            borderColor: null,
                            backgroundColor: null,
                            fontWeight: 700,
                          },
                        },
                        footer: {
                          borders: {
                            horizontal: false,
                            vertical: false,
                            outerHorizontal: false,
                            outerVertical: false,
                            match: true,
                          },
                          rows: [],
                          style: {
                            color: null,
                            borderColor: null,
                            backgroundColor: null,
                            fontWeight: 400,
                          },
                        },
                      },
                      padding: '20px 0 0 0',
                      fontSize: '12px',
                      lineHeight: '120%',
                    },
                  },
                  {
                    type: 'Table',
                    config: {
                      name: 'table',
                      tableConfig: {
                        columns: [
                          {
                            id: 'column1',
                            align: 'left',
                          },
                          {
                            id: 'column2',
                            align: 'right',
                          },
                        ],
                        body: {
                          rows: [
                            {
                              id: 'row-16ddd0c8-3661-4a93-9586-bb4bc06a641f',
                              columns: [
                                '{{{name}}}',
                                '{{{price}}}',
                              ],
                              type: 'iterable',
                              html: '{{#products}}<tr><td align=\"left\" style=\"background-color:#F9F9F9;color:#454648;border-top:1px solid #DEDFE2;border-bottom:1px solid #DEDFE2\">{{{name}}}\n</td><td align=\"right\" style=\"background-color:#F9F9F9;color:#454648;border-top:1px solid #DEDFE2;border-bottom:1px solid #DEDFE2\">{{{price}}}\n</td></tr>{{/products}}',
                              variable: 'products',
                            },
                          ],
                          borders: {
                            horizontal: true,
                            vertical: false,
                            outerHorizontal: true,
                            outerVertical: false,
                          },
                          style: {
                            borderColor: text,
                          },
                        },
                        header: {
                          rows: [
                            {
                              id: 'header1',
                              type: 'text',
                              columns: [
                                'Product',
                                'Price',
                              ],
                            },
                          ],
                          borders: {
                            horizontal: false,
                            vertical: false,
                            outerHorizontal: false,
                            outerVertical: false,
                            match: false,
                          },
                          style: {
                            fontWeight: '700',
                            color: text,
                          },
                        },
                        footer: {
                          borders: {
                            horizontal: false,
                            vertical: false,
                            outerHorizontal: false,
                            outerVertical: false,
                            match: false,
                          },
                          rows: [
                            {
                              id: 'row-e720edf4-d0d1-49b2-96e1-487e7b839279',
                              columns: [
                                'Payment Method:',
                                '{{{payment}}}',
                              ],
                              type: 'text',
                            },
                            {
                              id: 'row-8a90f3e4-fd64-42cf-97dd-cacb1d9fa4e9',
                              columns: [
                                '**Total:**',
                                '**{{{total}}}**',
                              ],
                              type: 'text',
                            },
                          ],
                          style: {
                            fontWeight: '400',
                          },
                        },
                      },
                      padding: '25px 0px 25px 0px',
                      fontSize: '12px',
                      lineHeight: '180%',
                    },
                  },
                  {
                    type: 'Divider',
                    config: {
                      color: accentBg,
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      padding: '10px 0 10px 0',
                    },
                  },
                  {
                    type: 'Text',
                    config: {
                      content: 'If you have trouble with the link above, copy and paste the URL below into your web browser.\n\n{{receipt}}',
                      padding: '0 0 0 0',
                      fontSize: '10px',
                      fontWeight: '400',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    }
  },
}

export { orderConfirmation }
