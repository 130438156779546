import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { Section } from './Section'
import Elements from './Element'
import { WatermarkSection } from './Section/watermark'
import { Link } from '../../Link'
import { useChannel } from '../../../hooks/useChannel'
import { useMaxSize } from '../../../hooks/useMaxSize'
import { useTemplate, TemplateProvider } from '../useTemplate'
import { fontFamilies } from './Config/defaults'
import { asciiCredit } from './ascii'
import { ElementDragContext } from './DragContext'
import { DragOverlay } from './DragOverlay'

import {
  IfMso, EmailTable, PreviewText, Raw, deepMerge,
  injectHtml, jsxToString, renderString, renderSections,
} from '../../../helpers'

import templateStyle from './style/template.scss'
import previewStyle from './style/preview.scss'

const Style = ({ style }) => (style?.toString ? (
  <style>{style.toString().replace(/\/\*[^\0]+$/g, '')}</style>
) : null)

Style.propTypes = { style: PropTypes.any }
Style.defaultProps = { style: undefined }

const PreviewLink = () => {
  const params = useParams()
  let root = './'
  const [hover, setHover] = React.useState(false)
  const channel = useChannel('hover/template', ({ data }) => setHover(data), [])

  // Root for shared sections are the section
  if (params.sharedSectionId) {
    // Get the url before the element path
    [root] = `./${params['*']}`.split('element/')
  } else if (params['*'] === '') root = '.' // Link should be active at .../build/ and .../build

  return (
    <Link
      label="Back to root"
      title=""
      data-hover={hover || undefined}
      className="template-root-link"
      onMouseEnter={() => channel.postMessage(true)}
      onMouseLeave={() => channel.postMessage(false)}
      to={root}
      end
    >
      Back
    </Link>
  )
}

PreviewLink.propTypes = {
  mode: PropTypes.string.isRequired,
}

const TemplateTable = React.forwardRef(({
  mode,
  style,
  children,
  height,
  ...props
}, ref) => (
  <EmailTable
    align="center"
    className="enveloop-email-background"
    style={{
      backgroundColor: style.backgroundColor || null,
      maxWidth: '100%',
      position: 'relative',
      height,
    }}
    data-mode={!['render', 'preview-html'].includes(mode) ? mode : null}
    onClickCapture={!['render', 'preview-html'].includes(mode) ? (e) => {
      // Prevent clicks on links rendered from user content
      if (e.target.tagName === 'A' && !e.target.href.match(window.location.host)) {
        e.preventDefault()
      }
    } : null}
    ref={ref}
    {...props}
  >
    <tbody>
      <tr>
        <td
          valign="top"
          align="center"
          style={{
            padding: style.padding,
          }}
        >
          { mode.match('builder') ? <PreviewLink mode={mode} /> : null }
          <EmailTable
            className="enveloop-email-container"
            width={!mode.match(/builder/) && !mode.match(/preview/) ? style.width : null}
            style={{
              backgroundColor: style.contentBackgroundColor || null,
              fontFamily: fontFamilies[style.fontFamily] || null,
              maxWidth: `${style.width}px`,
              fontSize: '16px',
            }}
          >
            {/* if children is a string, embed string as jsx otherwise do that */}
            { typeof children === 'string'
              ? <tbody {...injectHtml(children)} />
              : <tbody>{ children }</tbody> }
          </EmailTable>
        </td>
      </tr>
    </tbody>
  </EmailTable>
))

TemplateTable.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object.isRequired,
  mode: PropTypes.string,
  height: PropTypes.string,
}

TemplateTable.defaultProps = {
  mode: undefined,
  height: undefined,
}

const TemplateTableBody = React.forwardRef(({ content, ...props }, ref) => (
  <TemplateTable {...props} ref={ref}>
    { content }
  </TemplateTable>
))

const BuilderTemplateBody = ({
  sections, hooks, isActive, footerRef, ...props
}) => {
  const { height, contentRef } = useMaxSize({ footerRef })
  const dragging = (() => {
    if (hooks.dragging) {
      return hooks.dragging.match(/element/) ? 'element' : 'section'
    }
    return undefined
  })()
  return (
    <ElementDragContext
      sections={sections}
      onChangeElements={hooks.changeElementOrder}
      onChangeSections={hooks.changeSectionOrder}
      onComplete={hooks.saveTemplate}
      isActive={isActive}
      onDragStart={hooks.onDragStart}
      onDragEnd={hooks.onDragEnd}
      onDragCancel={hooks.onDragEnd}
    >
      <div
        className="level-template-builder-bg"
        style={{ minHeight: height }}
        data-dragging={dragging}
      >
        <div className="level-template-wrapper">
          { props.mode.match('builder') ? <PreviewLink mode={props.mode} /> : null }
          <TemplateTableBody height={`calc(${height} - 35px)`} ref={contentRef} {...props} />
          <DragOverlay />
        </div>
      </div>
    </ElementDragContext>
  )
}

const PreviewTemplateBody = ({ footerRef, ...props }) => {
  const { height, contentRef } = useMaxSize({ footerRef })
  return (
    <TemplateTableBody {...props} height={props.height || height} ref={contentRef} />
  )
}

const EmailTemplateBody = ({ sections, footerRef, height }) => {
  const {
    mode, config: templateConfig,
    hooks, isActive, previewVars,
  } = useTemplate()
  const { style, config } = templateConfig.variables

  const content = (() => {
    const watermark = <WatermarkSection mode={mode} style={style} watermark={hooks.watermark} />
    if (mode === 'render') {
      return renderString(`{{{ sections }}} ${jsxToString(watermark)}`)
    }
    if (mode.match(/builder/)) {
      return (
        <>
          {sections.map(({ variables, ...rest }) => (
            <Section
              key={variables.id}
              variables={variables}
              mode={mode}
              templateVariables={templateConfig.variables}
              {...rest}
            />
          ))}
          {watermark}
        </>
      )
    }

    return renderString(renderSections({
      templateVariables: previewVars,
      sections,
    }) + jsxToString(watermark))
  })()

  if (['render', 'preview-html'].includes(mode)) {
    return (
      <html lang="en">
        {/* Some clients like to strip the first head element */}
        <Raw text={asciiCredit} />
        <Raw text="<head></head>" />
        <head>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width" initial-scale="1" />
          <meta name="x-apple-disable-message-reformatting" />
          <meta name="color-scheme" content="light" />
          <meta name="supported-color-schemes" content="light" />
          <IfMso mode={mode}>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          </IfMso>
          <Style style={templateStyle} />
        </head>
        <body
          className="enveloop-email-background"
          style={{
            padding: '0',
            margin: '0',
            backgroundColor: style.backgroundColor || null,
          }}
        >
          <Style style={templateStyle} />
          <PreviewText text={config?.previewText} />
          <TemplateTable mode={mode} style={style}>
            { content }
          </TemplateTable>
        </body>
      </html>
    )
  }

  return (
    <>
      <Style style={templateStyle} />
      <Style style={previewStyle} />
      { (mode.match(/builder/)) ? (
        <BuilderTemplateBody
          footerRef={footerRef}
          hooks={hooks}
          isActive={isActive}
          sections={sections}
          mode={mode}
          style={style}
          content={content}
        />
      ) : (
        <PreviewTemplateBody
          footerRef={footerRef}
          height={height}
          mode={mode}
          style={style}
          content={content}
        />
      )}
    </>
  )
}

EmailTemplateBody.propTypes = {
  sections: PropTypes.array.isRequired,
  footerRef: PropTypes.object,
  height: PropTypes.string,
}

EmailTemplateBody.defaultProps = {
  footerRef: undefined,
  height: undefined,
}

const EmailTemplate = (props) => {
  const context = useTemplate()
  const {
    mode, config, footerRef, height,
  } = { ...context, ...props }

  if (context?.config && mode !== 'preview') {
    return <EmailTemplateBody {...config} height={height} footerRef={footerRef} mode={mode} />
  }

  return (
    <TemplateProvider {...props}>
      <EmailTemplateBody {...config} height={height} footerRef={footerRef} mode={mode} />
    </TemplateProvider>
  )
}

EmailTemplate.propTypes = {
  mode: PropTypes.string,
}

EmailTemplate.defaultProps = {
  mode: undefined,
}

EmailTemplate.getMustacheTypes = ({ template, config = 'previewConfig' }) => {
  let types = {
    iterable: [],
    conditional: [],
  }
  const { sections } = template[config] // previewConfig or liveConfig
  sections.forEach(({ variables }) => {
    types = deepMerge(types, Section.getMustacheTypes(variables))
    variables.columns.forEach(({ elements }) => {
      elements.filter((el) => !!el).forEach((element) => {
        const El = Elements[element.type]
        if (El?.getMustacheTypes) {
          const elTypes = El.getMustacheTypes({ element })
          types = deepMerge(types, elTypes)
        }
      })
    })
  })

  return types
}

export {
  EmailTemplate,
}
