import React from 'react'

const copyToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text)
  }
  // older browser fallback
  return document.execCommand('copy', true, text)
}

const useCopyText = () => {
  const [copied, setCopied] = React.useState(false)

  const copyText = async (text) => {
    try {
      await copyToClipboard(text)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 1500)
    } catch (err) {
      console.error(err)
    }
  }

  return { copyText, copied }
}

const ClipboardContext = React.createContext({})
const useClipboard = () => React.useContext(ClipboardContext)

const ClipboardProvider = (props) => {
  const [clipboard, setClipboard] = React.useState({
    updated: null,
    clips: [],
  })
  const copy = React.useCallback((name, data) => {
    setClipboard({ updated: {}, clips: [{ name, data }] })
  }, [])

  const paste = React.useCallback((index = -1) => {
    const data = clipboard.clips.slice(index)[0]
    setClipboard({ updated: {}, clips: clipboard.clips.slice(0, -1) })
    return data
  }, [clipboard.updated])

  const remove = React.useCallback((count = 1) => {
    setClipboard({ updated: {}, clips: clipboard.clips.slice(0, -count) })
  }, [clipboard.updated])

  const clear = React.useCallback(() => {
    remove(clipboard.clips.length)
  }, [clipboard.updated])

  const inspect = React.useCallback((index = -1) => (
    clipboard.clips.slice(index)[0] || {}
  ), [clipboard.updated])

  const value = React.useMemo(() => ({
    copy,
    paste,
    inspect,
    clear,
  }), [clipboard.updated])

  return <ClipboardContext.Provider value={value} {...props} />
}

export {
  useCopyText,
  copyToClipboard,
  useClipboard,
  ClipboardProvider,
}
