import React from 'react'

import { useTemplateConfig } from './useTemplateConfig'
import { Divider } from '../../../Divider'
import { Stack } from '../../../Stack'
import { Block } from '../../../Block'
import { Text } from '../../../Text'
import { Grid } from '../../../Grid'

import { TemplateStyle } from './Style'

import './config.scss'

const TemplateConfigDrawer = () => {
  const {
    slug,
    config,
    updateTemplate,
  } = useTemplateConfig()

  return (
    <Grid templateRows="min-content auto" align="normal" maxHeight>
      <Block space={7}>
        <Stack gap={6}>
          <Text tag="h3">Template Settings</Text>
          <Text tag="p">Templates are made up of sections. You can add, rearrange, and configure sections.</Text>
        </Stack>
      </Block>
      <Divider />
      <TemplateStyle
        updateTemplate={updateTemplate}
        templateConfig={config}
        slug={slug}
      />
    </Grid>
  )
}

export {
  TemplateConfigDrawer,
}
