import React from 'react'

import { useSection } from '../helpers'
import { SectionConfig } from './Config'
import { SharedSectionConfig } from './Shared'

const Section = () => {
  const section = useSection()

  if (!section) return null
  return section.sharedSectionId ? <SharedSectionConfig /> : <SectionConfig />
}

export { Section }
export { SectionConfigForm } from './Config'
