import React from 'react'
import PropTypes from 'prop-types'

import {
  dasherize,
  camelize,
  jsxToString,
  toColor,
} from '../../helpers'

import { sizes } from '../../constants/fontSize'
import * as icons from '../../icons'
import './icon.scss'

const iconNames = Object.keys(icons).map(dasherize)

const cascadeSize = (...args) => (
  args.find((a) => typeof a !== 'undefined')
)

const Icon = ({
  name,
  color,
  fill,
  size,
  className,
  width: widthProp,
  height: heightProp,
  ...rest
}) => {
  const Svg = icons[camelize(name)]

  if (!Svg) return null

  const width = cascadeSize(widthProp, heightProp, size)
  const height = cascadeSize(heightProp, width, size)

  return (
    <Svg
      className={`level-icon${className ? ` ${className}` : ''}`}
      data-name={name}
      fill={fill || toColor(color) || 'currentColor'}
      width={sizes[width] || width}
      height={sizes[height] || height}
      {...rest}
    />
  )
}

Icon.propTypes = {
  name: PropTypes.oneOf(iconNames).isRequired,
  size: PropTypes.oneOf(Object.keys(sizes).map((n) => parseInt(n, 10) || n)),
  fill: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ...toColor.propTypes,
}

Icon.defaultProps = {
  className: null,
  size: 'default',
  fill: undefined,
  width: undefined,
  height: undefined,
}

// Because SVGr doesn't keep the xmlns but when stringified, it is needed
const addXmlns = (str) => (
  str.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"')
)

const useStringIcon = (props) => React.useMemo(() => (
  addXmlns(jsxToString(<Icon {...props} />))
), [props])

export {
  Icon,
  iconNames,
  useStringIcon,
}
