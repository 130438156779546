import React from 'react'
import PropTypes from 'prop-types'
import * as Ariakit from '@ariakit/react'

import { toColor } from '../../helpers/color'

import './tooltip.scss'

const Tooltip = ({
  children, tip, arrow,
  color, backgroundColor, borderColor,
  ...props
}) => {
  const style = {
    color: toColor(color),
    backgroundColor: toColor(backgroundColor),
    borderColor: toColor(borderColor),
  }
  return (
    <Ariakit.TooltipProvider {...props}>
      <Ariakit.TooltipAnchor className="level-tooltip-anchor">
        { children }
      </Ariakit.TooltipAnchor>
      <Ariakit.Tooltip
        className="level-tooltip"
        style={style}
      >
        { arrow ? <Ariakit.TooltipArrow /> : null }
        { tip }
      </Ariakit.Tooltip>
    </Ariakit.TooltipProvider>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tip: PropTypes.any.isRequired,
  arrow: PropTypes.bool,
  color: toColor.propTypes.color,
  backgroundColor: toColor.propTypes.color,
  borderColor: toColor.propTypes.color,
}

Tooltip.defaultProps = {
  arrow: true,
  color: 'neutral-600',
  backgroundColor: 'neutral-25',
  borderColor: 'neutral-50',
}

export {
  Tooltip,
}
