import React from 'react'
import PropTypes from 'prop-types'

import {
  MenuItem as ReachMenuItem,
  MenuItems,
} from '@reach/menu-button'

import { Text } from '../Text'

const MenuItem = ({
  destructive,
  text,
  children,
  ...rest
}) => (
  <Text as={ReachMenuItem} {...rest} data-destructive={destructive}>
    { children || text }
  </Text>
)

MenuItem.propTypes = {
  children: PropTypes.node,
  destructive: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
}

MenuItem.defaultProps = {
  className: 'level-menu-item',
  destructive: null,
  text: null,
  children: null,
}

MenuItems.defaultProps = {
  className: 'level-menu-list',
}

export {
  MenuItem,
  MenuItems,
}
