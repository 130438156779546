import React from 'react'
import { useProfile } from './useProfile'
import { useAsUser } from './useAsUser'

const FullStoryOrgId = 'o-1FK2NZ-na1'
const FullStoryActive = process.env.NODE_ENV === 'production'
const FullStoryContext = React.createContext()

/* eslint-disable */
const includeFullStory = () => {
  (function (m, n, e, t, l, o, g, y) {
    if (e in m) { if (m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].') } return }
    g = m[e] = function (a, b, s) { g.q ? g.q.push([a, b, s]) : g._api(a, b, s) }; g.q = []
    o = n.createElement(t); o.async = 1; o.crossOrigin = 'anonymous'; o.src = `https://${_fs_script}`
    y = n.getElementsByTagName(t)[0]; y.parentNode.insertBefore(o, y)
    g.identify = function (i, v, s) { g(l, { uid: i }, s); if (v)g(l, v, s) }; g.setUserVars = function (v, s) { g(l, v, s) }; g.event = function (i, v, s) { g('event', { n: i, p: v }, s) }
    g.anonymize = function () { g.identify(!!0) }
    g.shutdown = function () { g('rec', !1) }; g.restart = function () { g('rec', !0) }
    g.log = function (a, b) { g('log', [a, b]) }
    g.consent = function (a) { g('consent', !arguments.length || a) }
    g.identifyAccount = function (i, v) { o = 'account'; v = v || {}; v.acctId = i; g(o, v) }
    g.clearUserCookie = function () {}
    g.setVars = function (n, p) { g('setVars', [n, p]) }
    g._w = {}; y = 'XMLHttpRequest'; g._w[y] = m[y]; y = 'fetch'; g._w[y] = m[y]
    if (m[y])m[y] = function () { return g._w[y].apply(this, arguments) }
    g._v = '1.3.0'
  }(window, document, window._fs_namespace, 'script', 'user'))
}
/* eslint-enable */

const getWindowFullStory = () => window[window._fs_namespace]

const canUseDOM = !!(
  typeof window !== 'undefined'
  && window.document
  && window.document.createElement
)

const FullStoryAPI = (fn, ...args) => {
  if (canUseDOM && getWindowFullStory()) {
    if (getWindowFullStory()[fn]) {
      return getWindowFullStory()[fn](...args)
    }
    return window.setTimeout(() => {
      FullStoryAPI(fn, ...args)
    }, 500)
  }

  return false
}

const useInitFullStory = (options = {}) => React.useCallback(({ org }) => {
  const {
    debug, script, host, namespace,
  } = options
  if (FullStoryActive && canUseDOM && org) {
    if (!getWindowFullStory()) {
      window._fs_debug = debug || false
      window._fs_host = host || 'fullstory.com'
      window._fs_script = script || 'edge.fullstory.com/s/fs.js'
      window._fs_org = FullStoryOrgId
      window._fs_namespace = namespace || 'FS'

      includeFullStory()
    }
  }

  return () => {
    if (getWindowFullStory()) {
      getWindowFullStory().shutdown()

      delete window[window._fs_namespace]
      delete window._fs_debug
      delete window._fs_host
      delete window._fs_org
      delete window._fs_namespace
      delete window._fs_script
    }
  }
}, [FullStoryActive])

const FullStoryProvider = (props) => {
  const { asUser } = useAsUser()
  const initFullStory = useInitFullStory()

  const identify = React.useCallback(({ id, name, email }) => {
    const enableSession = id && email && !email.endsWith('@enveloop.com') && !asUser

    if (enableSession) initFullStory({ org: FullStoryOrgId })

    if (id && enableSession) {
      FullStoryAPI(
        'identify',
        `${process.env.NODE_ENV}-${id}`,
        { displayName: name, email },
      )
    }
  }, [])

  return <FullStoryContext.Provider value={identify} {...props} />
}

const FullStory = () => {
  const { Profile } = useProfile()
  const fs = React.useContext(FullStoryContext)

  React.useEffect(() => {
    if (Profile?.id && fs) { fs(Profile) }
  }, [Profile?.id])

  return null
}

export {
  FullStory,
  FullStoryProvider,
}
