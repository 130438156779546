import React from 'react'
import PropTypes from 'prop-types'

import { CheckSwitch, CheckButton } from '../../../../Input'
import { Select, SelectMenu } from '../../../../Select'
import { Stack } from '../../../../Stack'
import { DragList } from '../../../../DragList'
import { TextIcon, Text } from '../../../../Text'
import { Divider } from '../../../../Divider'
import { Grid } from '../../../../Grid'
import { Button } from '../../../../Button'
import { Card } from '../../../../Card'
import { SliderInput } from '../../../../SliderInput'
import { ColorInput } from '../../../../Input/Color'
import {
  Tabs, TabList, Tab, TabPanels, TabPanel,
} from '../../../../Tabs'
import {
  copyObject, camelToTitle,
} from '../../../../../helpers'

import {
  addRow,
  removeRow,
  changeRowOrder,
  changeRowType,
  changeColumnCount,
} from '../../Element/Table/helpers'

import { useFormRef } from '../../../../../hooks/useForm'

// Create an array of values 1 - 6 for table column count
const columnOptions = new Array(10).fill(0).map((_, i) => ({ value: i + 1, label: i + 1 }))
const rowTypes = {
  text: {
    label: 'Text row',
    icon: 'text',
  },
  iterable: {
    label: 'Iterable Row',
    icon: 'list-tree',
  },
  conditional: {
    label: 'Conditional Row',
    icon: 'toggle-on',
  },
}
const rowTypeOptions = Object.entries(rowTypes).map(([value, options]) => (
  { value, ...options, iconGap: 5 }
))

const borderTypes = {
  horizontal: 'border-center-h',
  outerHorizontal: 'border-horizontal',
  vertical: 'border-center-v',
  outerVertical: 'border-vertical',
}

const BorderType = ({ rowGroup, tableConfig, onChange }) => {
  const { setValue } = useFormRef()
  const config = tableConfig[rowGroup].borders
  const value = config?.match
    ? { ...tableConfig.body.borders, ...config }
    : config

  const onChangeMatch = (event) => {
    // Update inputs to match row border values
    if (event.target.checked) {
      Object.keys(borderTypes).forEach((type) => {
        setValue(`${rowGroup}${type}`, tableConfig.body[type])
      })
    }
    onChange('match')(event)
  }

  return (
    <Stack gap={5}>
      <Text className="level-label">Borders</Text>
      <Stack gap={5}>
        { rowGroup !== 'body' ? <CheckSwitch label="Match Rows" name={`${rowGroup}Match`} defaultChecked={config.match} onChange={onChangeMatch} /> : null }
        { !value?.match ? (
          <Grid gap={4} columns={2}>
            { Object.entries(borderTypes).map(([type, icon]) => (
              <CheckButton label={camelToTitle(type)} icon={{ name: icon, size: 4 }} key={`${rowGroup}${type}`} name={`${rowGroup}${type}`} defaultChecked={value[type]} onChange={onChange(type)} />
            ))}
          </Grid>
        ) : null }
      </Stack>
    </Stack>
  )
}

BorderType.propTypes = {
  rowGroup: PropTypes.string.isRequired,
  tableConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

const RowConfig = ({ rowGroup, tableConfig }) => {
  const { onChangeInput } = useFormRef()
  const { style } = tableConfig[rowGroup]

  const onChangeConfig = (newConfig) => {
    if (onChangeInput) onChangeInput({ tableConfig: { ...tableConfig, ...newConfig } })
  }

  const onChangeFontWeight = ({ target: { value } }) => {
    const config = copyObject(tableConfig[rowGroup])
    config.style = { ...style, fontWeight: value }
    onChangeConfig({ [rowGroup]: config })
  }

  const onChangeColor = (type) => ({ color }) => {
    const config = copyObject(tableConfig[rowGroup])
    config.style = { ...style, [type]: color }

    onChangeConfig({ [rowGroup]: config })
  }

  const onChangeBorder = (key) => ({ target }) => {
    const config = copyObject(tableConfig)

    // Set config border setting
    config[rowGroup].borders[key] = target.checked

    // If changes are made to body, copy changes header and footer if match is enabled
    if (rowGroup === 'body') {
      ['header', 'footer'].forEach((type) => {
        if (config[type].borders.match) {
          config[type].borders = { ...config.body.borders, match: true }
        }
      })
    }

    // If change was made to header or footer and match is enabled
    // Copy body border config if match was enabled
    if (key !== 'body' && key === 'match' && target.checked) {
      config[rowGroup].borders = {
        ...config.body.borders,
        match: true,
      }
    }

    onChangeInput({ tableConfig: config })
  }

  return (
    <Stack gap={6}>
      <TableRows tableConfig={tableConfig} rowGroup={rowGroup} />
      <ColorInput name={`${rowGroup}Color`} label="Text Color" defaultValue={style.color} placeholder="inherit" onSelectColor={onChangeColor('color')} />
      <ColorInput name={`${rowGroup}BackgroundColor`} label="Background Color" defaultValue={style.backgroundColor} placeholder="none" onSelectColor={onChangeColor('backgroundColor')} />
      <BorderType rowGroup={rowGroup} tableConfig={tableConfig} onChange={onChangeBorder} />
      <ColorInput name={`${rowGroup}BorderColor`} label="Border Color" defaultValue={style.borderColor} placeholder={rowGroup === 'body' ? 'match text color' : 'match row borders'} onSelectColor={onChangeColor('borderColor')} />
      { typeof style.fontWeight !== 'undefined' ? <SliderInput label="Font Weight" name={`${rowGroup}FontWeight`} defaultValue={style.fontWeight} step={100} min={100} max={900} onChange={onChangeFontWeight} /> : null }
    </Stack>
  )
}

RowConfig.propTypes = {
  rowGroup: PropTypes.string.isRequired,
  tableConfig: PropTypes.object.isRequired,
}

const TableColumns = ({
  tableConfig,
}) => {
  const { onChangeInput } = useFormRef()
  const onChangeColumns = ({ target: { value } }) => {
    onChangeInput({ tableConfig: changeColumnCount({ tableConfig, count: value }) })
  }

  return (
    <Select name="columns" label="Columns" defaultValue={tableConfig.columns.length} options={columnOptions} onChange={onChangeColumns} />
  )
}

TableColumns.propTypes = {
  tableConfig: PropTypes.object.isRequired,
}

const TableRows = ({ tableConfig, rowGroup }) => {
  const { onChangeInput } = useFormRef()

  const onChangeConfig = (newConfig) => {
    if (onChangeInput) onChangeInput({ tableConfig: { ...tableConfig, ...newConfig } })
  }

  const onChangeRowOrder = ({ from, to }) => {
    onChangeConfig(changeRowOrder({
      tableConfig, rowGroup, from, to,
    }))
  }

  const onRemoveRow = (index) => {
    onChangeConfig(removeRow({ tableConfig, rowGroup, index }))
  }

  const onAddRow = () => {
    onChangeConfig(addRow({ tableConfig, rowGroup }))
  }

  const onChangeRow = (index) => (type) => {
    onChangeConfig(changeRowType({
      tableConfig, rowGroup, index, type,
    }))
  }

  return (
    <Stack gap={5}>
      { tableConfig[rowGroup].rows.length ? (
        <DragList theme="card" onChange={onChangeRowOrder}>
          { tableConfig[rowGroup].rows.map((row, index) => (
            <Grid key={row.id} className="level-drag-item" valign="center" templateColumns="min-content auto 1fr min-content" style={{ bakcgroundColor: '#fff' }} space={[3, 5]} gap={4} draggable>
              <TextIcon name="six-dots-grid" color="neutral-200" />
              <SelectMenu
                name={`${row.id}-type`}
                label={false}
                defaultValue={row.type}
                options={rowTypeOptions}
                theme="ghost"
                space={[2, 0]}
                onChange={onChangeRow(index)}
              />
              <span />
              <Button size={0} theme="subtle-destructive-ghost" onClick={() => onRemoveRow(index)} label="Delete" icon="trash" />
            </Grid>
          ))}
        </DragList>
      ) : (
        <Card space={[5, 0]} theme="well" align="center">
          <Text color="neutral-200">No Rows</Text>
        </Card>
      )}
      <Button
        size={4}
        icon="plus"
        text="Add Row"
        onClick={() => onAddRow()}
        grow
      />
    </Stack>
  )
}

TableRows.propTypes = {
  tableConfig: PropTypes.object.isRequired,
  rowGroup: PropTypes.string.isRequired,
}

const TableConfig = ({ value: tableConfig }) => (
  <Stack gap={8}>
    <TableColumns tableConfig={tableConfig} />
    <Divider flush={7} />
    <Tabs theme="nav-panel">
      <Stack gap={6}>
        <TabList>
          <Tab>Rows</Tab>
          <Tab>Header</Tab>
          <Tab>Footer</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <RowConfig rowGroup="body" tableConfig={tableConfig} />
          </TabPanel>
          <TabPanel>
            <RowConfig rowGroup="header" tableConfig={tableConfig} />
          </TabPanel>
          <TabPanel>
            <RowConfig rowGroup="footer" tableConfig={tableConfig} />
          </TabPanel>
        </TabPanels>
      </Stack>
    </Tabs>
  </Stack>
)

TableConfig.propTypes = {
  value: PropTypes.object.isRequired,
}

export {
  TableConfig,
}
