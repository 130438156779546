import React from 'react'
import PropTypes from 'prop-types'

import {
  Stack, Form, Divider,
  Block, Text, SectionLabel,
  pluralize,
} from '@level'

import { AiTemplateTestEditor, TemplateTestEditor } from './editor'

const TemplateTestForm = ({
  children, templateVariables, onSubmit, useAi,
}) => (
  <Form onSubmit={onSubmit}>
    { templateVariables?.length ? (
      <>
        <Block space={6}>
          <Stack gap={6}>
            <SectionLabel>Variables</SectionLabel>
            <Text tag="p">
              We found {templateVariables.length} {pluralize('variable', templateVariables.length)} in your template.
              Add values to preview them in your message.
            </Text>
            { useAi ? <AiTemplateTestEditor /> : <TemplateTestEditor /> }
          </Stack>
        </Block>
        <Divider />
      </>
    ) : null }
    { children }
  </Form>
)

TemplateTestForm.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  templateVariables: PropTypes.array,
  useAi: PropTypes.bool,
}

TemplateTestForm.defaultProps = {
  useAi: true,
  templateVariables: [],
}

export {
  TemplateTestForm,
}
