import React from 'react'
import PropTypes from 'prop-types'

import { Input, Textarea } from '../../../Input'
import { RadioPanel } from '../../../RadioPanel'
import { PaddingInput } from './Input/Padding'
import { ColorInput } from '../../../Input/Color'
import { SliderInput, SliderSelectInput } from '../../../SliderInput'
import { AlignInput } from './Input/Align'
import { ColumnLayoutInput } from './Input/ColumnLayout'
import { ColumnSpaceInput } from './Input/ColumnSpace'
import { FontFamilyInput } from './Input/FontFamily'
import { ImageInput } from './Input/Image'
import { Select } from '../../../Select'
import { TableConfig } from './Input/Table'

import { fontSizes } from './defaults'
import { borderStyles } from '../Element/Divider'
import { cornerStyles } from '../Element/Button'
import { titleize } from '../../../../helpers/text'
import { useConfigFormContext } from './Provider'
import { autoEscapeMustache } from '../../../../helpers/mustache'

const borderStylesOptions = Object.entries(borderStyles).map(([n, val]) => ({
  label: titleize(n),
  value: val,
}))

const Attribute = ({
  name, value,
}) => {
  const context = useConfigFormContext()
  const { type } = context
  if (name.match(/^color$/i)) {
    return <ColorInput label="Text Color" name={name} defaultValue={value} placeholder="inherit" />
  }

  if (name.match(/backgroundColor$/i)) {
    return (
      <ColorInput
        name={name}
        placeholder={type === 'Button' ? 'inherit link color' : 'none'}
        defaultValue={value}
      />
    )
  }

  if (name.match(/.+color$/i)) {
    return <ColorInput name={name} defaultValue={value} placeholder="inherit" />
  }

  if (name.match(/content$/i)) {
    return <Textarea name={name} defaultValue={value} />
  }

  if (name.match(/size/i)) {
    return <SliderSelectInput name={name} defaultValue={value} options={fontSizes} />
  }

  if (name.match(/lineHeight/i)) {
    return <SliderInput name={name} defaultValue={value} min={80} step={5} max={200} unit="%" />
  }

  if (name.match(/borderWidth/i)) {
    return <SliderInput name={name} label="Thickness" defaultValue={value} min={1} max={15} unit="px" />
  }

  if (name.match(/minWidth/i)) {
    const widthOptions = [
      { value: '0%', label: 'Auto' },
      { value: '50%', label: 'Half' },
      { value: '100%', label: 'Full' },
    ]
    return <RadioPanel name={name} label="Width" defaultValue={value} items={widthOptions} />
  }

  if (name.match(/cornerStyle/i)) {
    return <RadioPanel name={name} defaultValue={value} items={cornerStyles} />
  }

  if (name.match(/padding/i)) {
    return <PaddingInput name={name} defaultValue={value} />
  }

  if (name.match(/^align/i)) {
    return <AlignInput name={name} defaultValue={value} type="horizontal" />
  }

  if (name.match(/valign/i)) {
    return <AlignInput name={name} defaultValue={value} type="vertical" label="Column Align" />
  }

  if (name.match(/columnLayout/i)) {
    return <ColumnLayoutInput name={name} defaultValue={value} label="Layout" />
  }

  if (name.match(/fontFamily/i)) {
    return <FontFamilyInput name={name} value={value} />
  }
  if (name.match(/fontWeight/i)) {
    return <SliderInput name={name} defaultValue={value} step={100} min={100} max={900} />
  }

  if (name.match(/columnSpace/i)) {
    return <ColumnSpaceInput name={name} value={value} />
  }

  if (name.match(/elementSpacing/i)) {
    return <SliderInput name={name} defaultValue={value} step={5} unit="px" />
  }

  if (name.match(/borderStyle/i)) {
    return <Select name={name} defaultValue={value} options={borderStylesOptions} />
  }

  if (name.match(/src/i)) {
    return <ImageInput name={name} defaultValue={value} />
  }

  if (name.match(/href/)) {
    return (
      <Input name={name} defaultValue={value} placeholder="https://link.to/" />
    )
  }

  if (name.match(/alt/)) {
    return (
      <Input name={name} defaultValue={value} placeholder="Alt text description" />
    )
  }

  if (name.match(/tableConfig/)) {
    return <TableConfig name={name} value={value} />
  }

  if (name.match(/url/)) {
    return <Input name={name} defaultValue={value} setValueAs={autoEscapeMustache} />
  }

  return (
    <Input name={name} defaultValue={value} />
  )
}

Attribute.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
}

Attribute.defaultProps = {
  value: '',
}

export {
  Attribute,
}
