import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../../../Link'
import { useChannel } from '../../../../hooks/useChannel'

const SectionLink = ({ id, ...props }) => {
  const channel = useChannel(`hover/section/${id}`)
  return (
    <Link
      onMouseEnter={() => channel.postMessage(true)}
      onMouseLeave={() => channel.postMessage(false)}
      label="Edit section"
      className="level-template-section-link"
      to={`./section/${id}`}
      {...props}
    />
  )
}

SectionLink.propTypes = {
  id: PropTypes.string.isRequired,
}

export {
  SectionLink,
}
