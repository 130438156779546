import React from 'react'

import { Routes, Route } from 'react-router-dom'

import { Section } from './Section'
import { ElementConfig } from './Element'
import { DrawerMenu } from './DrawerMenu'
import { TemplateConfigDrawer } from './Drawer'
import { useTemplateConfig, TemplateConfigProvider } from './useTemplateConfig'

const TemplateConfigIndex = () => {
  const { showDrawerMenu } = useTemplateConfig()
  if (showDrawerMenu) {
    return (<DrawerMenu />)
  }

  return (
    <Routes>
      <Route index element={<TemplateConfigDrawer />} />
      <Route path="section/:sectionId" element={<Section />} />
      <Route path="element/:elementId" element={<ElementConfig />} />
    </Routes>
  )
}

export {
  TemplateConfigIndex,
  TemplateConfigProvider,
  useTemplateConfig,
}
export * from './Config'
export { SectionConfigForm } from './Section'
export { ElementConfig } from './Element'
export { DrawerMenu }
