import React from 'react'
import {
  Editor, Template, Stack, MagicLoader, Button, Grid,
  useDebounce,
} from '@level'

import { useTemplate, useTemplateTest } from '../context'
import { useAiVars } from './useAiVars'

const useTestEditor = () => {
  const { getData } = useTemplate()
  const { previewVars, setPreviewVars } = useTemplateTest()
  const [refresh, setRefresh] = React.useState(previewVars)

  const editorContent = React.useMemo(() => {
    const vars = Template.getTemplateVars({ template: getData().template, previewVars })
    const json = JSON.stringify(vars, null, 2)
    return json === '{}' ? '{\n  \n}' : json
  }, [refresh, getData()?.template.previewTemplateVariables])

  const getEmptyVars = () => Template.getTemplateVars({ template: getData().template })

  const setEditorContent = (str) => {
    setPreviewVars(str)
    setRefresh(str)
  }

  const resetEditor = () => {
    setEditorContent({})
  }

  React.useEffect(() => {
    if (JSON.stringify(previewVars) === '{}') {
      setPreviewVars(JSON.parse(editorContent))
    }
  }, [])

  const editorOnChange = useDebounce((text) => {
    if (text) {
      try {
        setPreviewVars(JSON.parse(text))
      } catch {
        // do nothing
      }
    }
  }, [], 500)

  return {
    previewVars,
    editorContent,
    getEmptyVars,
    resetEditor,
    setEditorContent,
    editorOnChange,
  }
}

const TemplateTestEditor = () => {
  const { editorContent, editorOnChange, resetEditor } = useTestEditor()
  return (
    <Stack gap={6}>
      <Editor content={editorContent} lang="js" onChange={editorOnChange} />
      <Button text="Reset" onClick={resetEditor} />
    </Stack>
  )
}

const AiTemplateTestEditor = () => {
  const {
    editorContent,
    setEditorContent,
    editorOnChange,
    getEmptyVars,
    resetEditor,
  } = useTestEditor()
  const { fetch, isLoading } = useAiVars({ previewVars: getEmptyVars(), setEditorContent })
  return (
    <Stack gap={6}>
      <MagicLoader loading={isLoading}>
        <Editor content={editorContent} lang="js" onChange={editorOnChange} />
      </MagicLoader>
      <Grid gap={5} templateColumns="1fr auto">
        <Button icon="wand-magic-sparkles" text="Magic Fill" onClick={fetch} isLoading={isLoading} />
        <Button icon="trash" label="Clear Values" onClick={resetEditor} />
      </Grid>
    </Stack>
  )
}

export {
  useTestEditor,
  TemplateTestEditor,
  AiTemplateTestEditor,
}
