import mustache from 'mustache'
import { renderMarkdown } from './markdown'
import { formatDateAsWords } from './date'

const templateStatusText = ({ published, publishedAt }) => {
  let icon
  let color
  let text
  if (!publishedAt) {
    icon = 'circle-minus'
    color = 'neutral-400'
    text = 'Draft'
  } else if (publishedAt && !published) {
    icon = 'circle-check'
    color = 'yellow-800'
    text = 'Live • Unpublished Changes'
  } else if (published) {
    icon = 'circle-check'
    color = 'green-600'
    text = 'Live'
  }
  return {
    icon,
    color,
    text,
  }
}

const templateUpdateText = ({ updatedAt, published, publishedAt }) => {
  if (published) return `Published ${formatDateAsWords(publishedAt)}`
  if (updatedAt) return `Updated ${formatDateAsWords(updatedAt)}`
  return null
}

const getColumnElement = ({ column, elementId }) => {
  const index = column.elements.findIndex((el) => el?.id === elementId)
  const element = column.elements[index]
  return (index !== -1) ? {
    element,
    elementId: element.id,
    elementIndex: index,
    elementType: element.type,
  } : null
}

const getSectionIndex = ({ config, section, sectionId = section?.variables?.id }) => (
  config.sections.findIndex(({ variables }) => (
    variables.id === sectionId
  ))
)

const getTemplateSection = ({ config, sectionId }) => {
  const sectionIndex = getSectionIndex({ config, sectionId })
  const section = config.sections[sectionIndex]
  return {
    sectionIndex,
    section,
  }
}

const getTemplateSectionElement = ({ config, sectionId, elementId }) => {
  const sectionIndex = getSectionIndex({ config, sectionId })
  const section = config.sections[sectionIndex]
  return section.variables.columns.reduce((acc2, col, index) => {
    if (acc2) return acc2
    const column = getColumnElement({ config, column: col, elementId })
    return column ? {
      ...column,
      section,
      sectionId: section.variables.id,
      sectionIndex,
      columnIndex: index,
    } : null
  }, null)
}

const mapValues = ({ variables, callback }) => {
  if (typeof variables === 'string') {
    return callback({ val: variables })
  }

  const renderedVars = {}

  Object.keys(variables).forEach((key) => {
    const val = variables[key]
    const valType = typeof val

    if (Array.isArray(val)) {
      renderedVars[key] = val.map((v) => mapValues({ variables: v, callback }))
    } else if (valType === 'object' && val !== null) {
      renderedVars[key] = mapValues({ variables: val, callback })
    } else if (valType === 'string') {
      renderedVars[key] = callback({ val, key })
    } else {
      renderedVars[key] = val
    }
  })

  return renderedVars
}

const renderVariables = (variables) => {
  const callback = ({ val, key: type }) => renderMarkdown(val, { type })
  return mapValues({ variables, callback })
}

const renderTemplateVariables = (variables) => {
  const callback = ({ val }) => {
    // If there are newlines in the content, treat it like block content
    // Otherwise render it as inline markdown text
    const type = val.trim().includes('\n') ? 'content' : 'text'
    return renderMarkdown(val, { type })
  }
  return mapValues({ variables, callback })
}

const renderSections = ({
  templateVariables = {}, sections = [],
}) => {
  // Set preview mode true (don't render use variables) if:
  // templateVariables are not set, or template varialbes are empty
  const previewMode = !templateVariables
    || !Object.values(templateVariables).filter((v) => v).length

  return sections.reduce((acc, section) => {
    const body = mustache.render(section.body, renderVariables(section.variables))
    if (previewMode) return acc + body
    // If template variables are presenet render content again with those variables
    return acc + mustache.render(body, renderTemplateVariables(templateVariables))
  }, '')
}

// Get an element and its place in a section
// element, elementId: Accepts an element object or an elementId
// Returns: a sectionElement ({ section, element, columnIndex, elementIndex })
const getTemplateElement = ({ config, sectionId, elementId }) => {
  if (!elementId) return {}
  if (sectionId) return getTemplateSectionElement({ config, sectionId, elementId })

  return config.sections.reduce((acc, section, sectionIndex) => (
    acc || section.variables.columns.reduce((acc2, col, index) => {
      if (acc2) return acc2
      const column = getColumnElement({ config, column: col, elementId })
      return column ? {
        ...column,
        section,
        sectionId: section.variables.id,
        sectionIndex,
        columnIndex: index,
      } : acc
    }, null)
  ), null)
}

export {
  renderVariables,
  renderSections,
  getTemplateSection,
  getTemplateElement,
  templateStatusText,
  templateUpdateText,
}
