import React from 'react'

import {
  Stack, Input,
  P, copyTemplate,
} from '@level'

import {
  useProject, useFormQuery, useFormDialog,
} from '@app/hooks'

import {
  createTemplateQuery,
  updateTemplateQuery,
  deleteTemplateQuery,
  publishTemplateQuery,
  revertTemplateQuery,
} from './queries'

const useCopyTemplate = (hookProps) => {
  const { Project } = useProject()

  const sendDialog = useFormDialog({
    query: ({ name, previewConfig: config, type }) => (
      createTemplateQuery({
        config: copyTemplate({ config, type }).config,
        name,
        type,
        Project,
      })
    ),
    ...hookProps,
  })

  return ({ data, ...rest }) => sendDialog({
    title: 'Duplicate Template',
    confirmText: 'Duplicate Template',
    data,
    content: (
      <Stack gap={5}>
        <P>
          You are about to create a copy of the template: {data.name}.
        </P>
        <Input
          name="name"
          label="Template name"
          placeholder="My Template"
          defaultValue={`${data.name} (Copy)`}
          required
        />
      </Stack>
    ),
    ...rest,
  })
}

const useRevertTemplate = (hookProps) => {
  const sendDialog = useFormDialog({
    query: revertTemplateQuery,
    ...hookProps,
  })

  return ({ data, ...rest }) => sendDialog({
    title: 'Revert Changes?',
    content: `This will revert all changes to the "${data.name}" template since the last publish. Are you sure?`,
    confirmText: 'Revert Changes',
    destructive: true,
    data,
    ...rest,
  })
}

const usePublishTemplate = () => {
  const { onSubmit } = useFormQuery({
    query: publishTemplateQuery,
  })
  return onSubmit
}

const useRenameTemplate = (hookProps) => {
  const sendDialog = useFormDialog({
    query: updateTemplateQuery,
    ...hookProps,
  })

  return ({ data, ...rest }) => sendDialog({
    title: 'Rename Template',
    confirmText: 'Save Changes',
    content: (
      <Stack gap={6}>
        <Input name="name" label="Template Name" defaultValue={data.name} />
        <Input
          name="slug"
          label="Template Slug"
          defaultValue={data.slug}
          description="Slug must be a unique. If you change this be sure to update your app's code."
        />
      </Stack>
    ),
    data,
    ...rest,
  })
}

const useDeleteTemplate = (hookProps) => {
  const sendDialog = useFormDialog({
    query: deleteTemplateQuery,
    ...hookProps,
  })

  return (props) => (
    sendDialog({
      confirmText: 'Delete',
      title: 'Delete Template',
      content: 'You are about to delete this template. Are you sure?',
      destructive: true,
      ...props,
    })
  )
}

export {
  useRenameTemplate,
  useCopyTemplate,
  useDeleteTemplate,
  usePublishTemplate,
  useRevertTemplate,
}
